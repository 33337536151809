/* eslint-disable no-unused-vars */
import {
  Divider,
  Tabs,
  Form,
  Button,
  Card,
  Input,
  Row,
  Col,
  Upload,
  Avatar,
  message,
  Modal,
  FloatButton,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../helpers/functions';
import { Spin } from 'antd';
import Helmet from '../common/helmet';
import TextArea from 'antd/es/input/TextArea';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { beforeUpload } from '../../helpers/beforeUpload';
import { FaEye, FaFilePdf } from 'react-icons/fa';
import { useUnload } from '../../helpers/useUnload';
import ExperienceDetails from './experienceDetails';
import ChangeExpertPassword from './changeExpertPassword';
import CustomSelect from '../common/customSelect';
import { useNavigate, useBlocker } from 'react-router-dom';
import ImgCrop from 'antd-img-crop';

export const ExpertDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { expertDetailById, isExpertDetailLoading } = useSelector(state => state.requests);

  const { isChaPassLoading, changePassMsg } = useSelector(state => state.auth);
  const { isLocationLoading, locationList } = useSelector(state => state.common);
  const { imageUrlFromDB, isImageUploadingToDBLoading, isImageUploadFailed } = useSelector(
    state => state.requests,
  );

  const { isUpdating } = useSelector(state => state.auth);

  const [showProfilePage, setShowProfilePage] = useState(true);
  const [updateUserDetailsForm] = Form.useForm();
  const [isUpdateDetailsFormChanged, setIsUpdateDetailsFormChanged] = useState(false);
  const [initialDetailsFormValue, setInitialDetailsFormValue] = useState();
  const [fileList, setFileList] = useState([]);

  const [expertBiographyAndImage, setExpertBiographyAndImage] = useState({
    bio: null,
    avatar: null,
  });
  const [updateDetailFormData, setUpdateDetailFormData] = useState({
    id: getUserData().id,
    first_name: getUserData().first_name,
    last_name: getUserData().last_name,
    location_ids: getUserData()?.locations?.length ? [getUserData().locations[0]?.id] : [],
    email: getUserData().email,
    organization: getUserData().organization,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    blocker?.proceed();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    blocker?.reset();
  };

  useEffect(() => {
    if (locationList.length === 0) {
      dispatch({ type: 'GET_LOCATION_REQUEST', formData: { status: 'active' } });
    }

    if (getUserData().id) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: getUserData().id });
      dispatch({
        type: 'GET_EXPERT_SERVICE_AND_RATE_DETAIL_BY_ID',
        formData: { expert_id: getUserData().id },
      });
      dispatch({
        type: 'GET_EXPERT_EXPERIENCE_DETAIL_BY_ID',
        formData: { expert_id: getUserData().id },
      });
      dispatch({
        type: 'GET_EXPERT_LICENSES_DETAIL_BY_ID',
        formData: { expert_id: getUserData().id },
      });
      dispatch({
        type: 'GET_EXPERT_DEGREES_DETAIL_BY_ID',
        formData: { expert_id: getUserData().id },
      });
      if (imageUrlFromDB) {
        setExpertBiographyAndImage(prevData => {
          return { ...prevData, avatar: imageUrlFromDB };
        });
      }
    }
  }, [dispatch, isUpdating, isChaPassLoading, changePassMsg]);

  // Handle image upload being set automatically by ant upload even if it is not done
  useEffect(() => {
    if (isImageUploadingToDBLoading) {
      const FILE_LIST_UPLOADING = fileList.map(file => {
        file.status = 'uploading';
        return file;
      });
      setFileList(FILE_LIST_UPLOADING);
    } else {
      if (fileList.length > 0) {
        const FILE_LIST_DONE = fileList.map(file => {
          file.status = 'done';
          setExpertBiographyAndImage(prevData => {
            return { ...prevData, avatar: imageUrlFromDB };
          });
          return file;
        });
        setFileList(FILE_LIST_DONE);
      }
    }
    if (isImageUploadFailed) {
      setFileList([]);
      message.error('Something went wrong during upload. Please try again.');
    }
  }, [isImageUploadFailed, isImageUploadingToDBLoading]);

  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  //🏁 START Logic to block user navigation if form isn't updated

  useUnload(e => {
    // this will show the browser's default confirm
    e.preventDefault();
  }, isUpdateDetailsFormChanged);

  let shouldBlock = React.useCallback(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname,
    [],
  );
  // Block navigating elsewhere when data has been entered into the input
  let blocker = useBlocker(shouldBlock);

  // Reset the blocker on page load (blocker proceed & reset won't be defined otherwise)
  useEffect(() => {
    blocker.state = 'unblocked';
  }, []);

  // set initial details form state once
  useEffect(() => {
    const IS_INITIAL_DETAILS_FORM_STATE_EMPTY =
      initialDetailsFormValue && Object.keys(initialDetailsFormValue).length === 0;

    const IS_DETAILS_FORM_EMPTY =
      updateUserDetailsForm.getFieldsValue() &&
      Object.keys(updateUserDetailsForm.getFieldsValue()).length !== 0;

    if (
      !initialDetailsFormValue ||
      (IS_INITIAL_DETAILS_FORM_STATE_EMPTY && IS_DETAILS_FORM_EMPTY)
    ) {
      setInitialDetailsFormValue(updateUserDetailsForm.getFieldsValue());
    }
  }, [updateUserDetailsForm, updateUserDetailsForm.getFieldsValue()]);

  useEffect(() => {
    if (blocker.state === 'blocked' && isUpdateDetailsFormChanged) {
      setIsModalOpen(true);
    } else {
      blocker.proceed?.();
    }
  }, [blocker, blocker.state, isUpdateDetailsFormChanged]);

  //🏁 END Logic to block user navigation if form isn't updated

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        return locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };

  const handleChange = info => {
    setFileList(info.fileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onUpdateDetailFormSubmit = () => {
    if (
      updateDetailFormData.email &&
      updateDetailFormData.first_name &&
      updateDetailFormData.last_name
    ) {
      const imageData = fileList[0] ? fileList[0].thumbUrl.split(',')[1] : null;
      const imageName = fileList[0] ? fileList[0].name : null;
      const imageSize = fileList[0] ? fileList[0].size : null;
  
      dispatch({
        type: 'UPDATE_PROFILE_REQUEST',
        formData: {
          ...updateDetailFormData,
          bio: expertBiographyAndImage?.bio ? expertBiographyAndImage?.bio : expertDetailById?.bio,
          avatar: expertBiographyAndImage?.avatar
            ? expertBiographyAndImage?.avatar
            : expertDetailById?.avatar,
          profileImageData:{
            file: imageData,
          filename: imageName,
          fileSize:imageSize}
        },
      });
      setIsUpdateDetailsFormChanged(false);
    } else {
      message.warning('Input field cannot be empty');
    }
  };
  const handlePrintNavigation = () => {
    window.open(`/dashboard/expert/experiences/true`, '_blank');
  };
  const EXPERT_DETAILS = (
    <section className="profile d-flex-a-c">
      <Helmet title="Profile" />
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={true}>
          <div className="d-flex-between " style={{ alignItems: 'start' }}>
            <p className="m-title f20 tl">Account Details</p>
            <Button
              className="d-flex-a-c"
              type="primary"
              onClick={() => navigate(`/find-expert-detail/${expertDetailById.id}`)}
              icon={<FaEye />}
            >
              View as Client
            </Button>
          </div>
          {/* Add form provider to track form changes */}
          <Form.Provider
            onFormChange={() => {
              if (
                JSON.stringify(updateUserDetailsForm.getFieldsValue()) !==
                JSON.stringify(initialDetailsFormValue)
              ) {
                setIsUpdateDetailsFormChanged(true);
              } else {
                setIsUpdateDetailsFormChanged(false);
              }
            }}
          >
            <Form
              form={updateUserDetailsForm}
              layout="vertical"
              name="basic"
              hideRequiredMark={true}
              autoComplete="off"
              validateMessages={validateMessages}
            >
              <Row span={12} style={{ gap: '20px' }}>
                
                <Form.Item label="" name="avatar">
                  <Avatar
                    size={150}
                    src={
                      !(!expertBiographyAndImage?.avatar
                        ? expertDetailById?.avatar
                        : expertBiographyAndImage?.avatar)
                        ? expertIcon
                        : !expertBiographyAndImage?.avatar
                          ? expertDetailById?.avatar
                          : expertBiographyAndImage?.avatar
                    }
                    alt="Display Profile"
                  />
                </Form.Item>

                <Form.Item
                  initialValue={
                    !(!expertBiographyAndImage?.avatar
                      ? expertDetailById?.avatar
                      : expertBiographyAndImage?.avatar)
                      ? expertIcon
                      : !expertBiographyAndImage?.avatar
                        ? expertDetailById?.avatar
                        : expertBiographyAndImage?.avatar
                  }
                  label=""
                  name="avatar"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please upload your profile picture',
                    },
                  ]}
                >
                  <ImgCrop rotationSlider>
                    <Upload
                      progress
                      accept="image/svg,image/gif,image/png,image/bmp,image/jpeg"
                      beforeUpload={beforeUpload}
                      fileList={fileList}
                      name="attachment"
                      listType="picture-card"
                      onChange={handleChange}
                      onPreview={onPreview}
                      customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
                    >
                      {fileList.length < 1 && '+ Upload'}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name={['first_name']}
                    label="First Name"
                    initialValue={expertDetailById?.first_name}
                  >
                    <Input
                      size="large"
                      placeholder="First Name"
                      onChange={e => {
                        setUpdateDetailFormData(prevData => {
                          return { ...prevData, first_name: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['last_name']}
                    label="Last Name"
                    initialValue={expertDetailById?.last_name}
                  >
                    <Input
                      size="large"
                      placeholder="Last Name"
                      onChange={e => {
                        setUpdateDetailFormData(prevData => {
                          return { ...prevData, last_name: e.target.value };
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  initialValue={expertDetailById?.organization}
                  label="Business Name"
                  name="organization"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your business name',
                    },
                    {
                      max: 100,
                      message: 'Maximum 100 characters are allowed',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Business Name"
                    onChange={e => {
                      setUpdateDetailFormData(prevData => {
                        return { ...prevData, organization: e.target.value };
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Form.Item
                initialValue={expertDetailById?.email}
                name={['email']}
                label="Email address"
                rules={[{ type: 'email' }]}
              >
                <Input
                  label="Email Address"
                  readOnly
                  disabled
                  size="large"
                  placeholder="Email"
                  onChange={e => {
                    setUpdateDetailFormData(prevData => {
                      return { ...prevData, email: e.target.value };
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                initialValue={expertDetailById?.bio}
                label="Biography"
                name={['bio']}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your biography',
                  },
                  {
                    max: 2000,
                    message: 'Maximum 2000 characters are allowed',
                  },
                ]}
              >
                <TextArea
                  rows={6}
                  size="large"
                  placeholder="Biography"
                  onChange={e => {
                    setExpertBiographyAndImage(prevData => {
                      return { ...prevData, bio: e.target.value };
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                initialValue={
                  expertDetailById?.locations?.length ? expertDetailById?.locations[0]?.id : null
                }
                name={'locations'}
                label="Location"
              >
                <CustomSelect
                  style={{
                    width: '100%',
                  }}
                  size="large"
                  showSearch
                  allowClear
                  loading={isLocationLoading}
                  onChange={val => {
                    setUpdateDetailFormData(prevData => {
                      return { ...prevData, location_ids: [val] };
                    });
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionsList(locationList)}
                />
              </Form.Item>
            </Form>
          </Form.Provider>

          <Divider className="mt10 mb10" />
          <Form.Item>
            <Button type="link" size={'large'} onClick={() => setShowProfilePage(false)}>
              Need to change password?
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="float-r"
              size="large"
              onClick={onUpdateDetailFormSubmit}
              // disabled={isUpdateProfileButtonDisabled}
            >
              Update Detail
            </Button>
          </Form.Item>
        </Card>
      </div>
    </section>
  );
  const items = [
    {
      label: 'Account Details',
      key: 'item-1',
      children: (
        <>
          {showProfilePage ? EXPERT_DETAILS : <ChangeExpertPassword {...{ setShowProfilePage }} />}
        </>
      ),
    },
    {
      label: 'Experience Details',
      key: 'item-2',
      children: <ExperienceDetails></ExperienceDetails>,
    },
  ];
  return (
    <section className="expert-detail-body">
      {isExpertDetailLoading ? (
        <div
          style={{ width: (width * 80) / 100, height: (height * 50) / 100 }}
          className="d-flex-a-c"
        >
          <Spin />
        </div>
      ) : (
        <>
          <FloatButton.Group shape="circle">
            <FloatButton
              tooltip="Edit Expert Detail"
              description="Edit"
              icon={<EditOutlined />}
              onClick={() => navigate('/expert/profile')}
            />
            <FloatButton
              tooltip="Print PDF"
              description="Print"
              icon={<FaFilePdf color="#a50a00" />}
              onClick={handlePrintNavigation}
            />
          </FloatButton.Group>
          <Tabs tabPosition={'left'} size="large" items={items}></Tabs>
        </>
      )}
      {/* Modal on navigation without saving changes */}

      <Modal title="Confirm Navigation" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Changes you made might not be saved</p>
      </Modal>
    </section>
  );
};
