import { Avatar, Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatDateBlog, shareOnSocial } from '../../helpers/functions';
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { FACEBOOK_SHARE_URL } from '../../helpers/constant';

const BlogBox = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const blogFromRedux = useSelector(state => state?.common?.singleBlog);
  const isBlogLoading = useSelector(state => state?.common?.isBlogLoading);
  const [blog, setBlog] = useState({});
  useEffect(() => {
    dispatch({ type: 'GET_SINGLE_BLOG', id: location?.state || location?.pathname?.split('/')[3] });

  }, [location.state]);

  useEffect(() => {
    setBlog(blogFromRedux);

    return () => {
      setBlog({});
    }
  }, [blogFromRedux]);



  return (
    <>
      <Row style={{ minHeight: '100vh', backgroundColor: 'rgba(255,255,255)', padding: '5rem' }}>
        {Object.keys(blog).length > 0 ? (
          <>
            <Col
              span={12}
            >
              <ArrowLeftOutlined style={{ fontSize: '1.5rem', color: 'rgb(74,127,234)' }} onClick={() => window.history.go(-1)} />
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}>
                <img
                  style={{
                    width: '80%',
                    objectFit: 'cover',
                    position: 'sticky',
                    top: '0',
                    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 10px',
                  }}
                  src={blog?.featured_image}
                  alt="Blog"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}>
                  <div className='d-flex-a-c'>
                    <p style={{ marginTop: '1rem', color: 'rgb(74,127,234)' }}>Share This: </p>
                    <span className="mr10 ml20">
                      <AiOutlineTwitter onClick={() => shareOnSocial('twitter', blog?.title, window.location.href)} className="cursor-p" size={22} color="#6B7280" />
                    </span>
                    <span className="mr10">
                      <RiFacebookFill className="cursor-p" size={22} color="#6B7280" onClick={() => shareOnSocial('facebook', blog?.title, window.location.href)} />
                    </span>
                    <span>
                      <AiFillLinkedin className="cursor-p" size={22} color="#6B7280" onClick={() => shareOnSocial('linkedin', blog?.title, window.location.href)} />
                    </span>
                  </div>
                  <div className='d-flex-a-c mt10' style={{ maxWidth: '50%', flexWrap: 'wrap' }}>
                    {
                      !!blog?.tags?.length && blog?.tags?.map((tag, index) => (
                        <span className='tag mr10' key={index}>#{tag?.name}</span>

                      ))
                    }
                  </div>
                </div>
              </div>
            </Col>{' '}
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
            >
              <h1 style={{ color: 'black' }}>{blog?.title}</h1>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: '3rem' }}>
                {blog?.author_image && <span>
                  <Avatar size="large" style={{ backgroundColor: 'transparent' }}  >
                    <img src={blog?.author_image} alt="avatar" />
                  </Avatar>
                </span>}
                {blog?.author_name && <strong>{blog?.author_name}</strong>}
                <span style={{ color: '#6b7280' }} >{formatDateBlog(blog?.created_at)}</span>
              </div>
              <p
                style={{ textAlign: 'start', lineHeight: '2rem', fontSize: '1.2rem' }}
                dangerouslySetInnerHTML={{ __html: blog?.body }}
              ></p>
            </Col>
          </>
        ) : (
          isBlogLoading && <Spin />
        )}
      </Row>
    </>
  );
};

export default BlogBox;
