import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ImgPlaceholder from '../../assets/images/image-placeholder.png';
import PdfPlaceholder from '../../assets/images/pdf-placeholder.png';
import DocsPlaceholder from '../../assets/images/docx-placeholder.png';
import ExcelPlaceholder from '../../assets/images/excel-placeholder.png';
import expertIcon from '../../assets/images/profile_placeholder.png';
import { breakName, convertTimeToTimezone, formatDateTo, getUserData, role, showCustomAvatarName } from '../../helpers/functions';
import { ROLE_NAME } from '../../helpers/constant';
import { Avatar, Image } from 'antd';

export const MessageGroupCard = props => {
  const { data, unreadMessages, setSelectedThread } = props;
  const [profileData, setProfileData] = useState();
  const { selectedTabGroup, unReadMessagesRec } = useSelector(state => state.requests);
  const [threadUnreadMessages, setThreadUnreadMessages] = useState(0)

  const dispatch = useDispatch();

  useEffect(() => {
    data?.participants?.map(el => {
      if (el?.id !== getUserData().id) {
        return setProfileData(el);
      }
      return null;
    });
  }, [data]);

  const onMessageGroupCardClick = () => {
    dispatch({ type: 'SET_SELECTED_TAB_GROUP', id: data?.id });
    dispatch({ type: 'MARK_ALL_MESSAGES_AS_READ', formData: { thread_id: data?.id } });
    setSelectedThread(data);
  };

  const renderPlaceholderBasedOnType = (type, variant) => {
    switch (type) {
      case 'image': {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            {variant === 'from' ? (
              <>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '8px' }}
                  preview={false}
                  src={ImgPlaceholder}
                  alt="img"
                />
                <p>Photo</p>
              </>
            ) : (
              <>
                <p>You: </p>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '8px' }}
                  preview={false}
                  src={ImgPlaceholder}
                  alt="img"
                />
                <p>Photo</p>
              </>
            )}
          </div>
        );
      }
      case 'pdf': {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            {variant === 'from' ? (
              <>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={PdfPlaceholder}
                  alt="pdf"
                />
                <p>Pdf</p>
              </>
            ) : (
              <>
                <p>You: </p>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={PdfPlaceholder}
                  alt="pdf"
                />
                <p>Pdf</p>
              </>
            )}
          </div>
        );
      }
      case 'docs': {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            {variant === 'from' ? (
              <>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={DocsPlaceholder}
                  alt="docs"
                />
                <p>Docs</p>
              </>
            ) : (
              <>
                <p>You: </p>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={DocsPlaceholder}
                  alt="docs"
                />
                <p>Docs</p>
              </>
            )}
          </div>
        );
      }
      case 'excel': {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            {variant === 'from' ? (
              <>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={ExcelPlaceholder}
                  alt="excel"
                />
                <p>Excel</p>
              </>
            ) : (
              <>
                <p>You: </p>
                <Image
                  style={{ width: '1vw', height: '1vw', marginInline: '3px', borderRadius: '4px' }}
                  preview={false}
                  src={ExcelPlaceholder}
                  alt="excel"
                />
                <p>Excel</p>
              </>
            )}
          </div>
        );
      }
      default: {
        return;
      }
    }
  };

  useEffect(() => {
    if (unReadMessagesRec && unReadMessagesRec.length > 0 && data) {
      const unreadMessageCount = unReadMessagesRec.filter((obj) => obj?.subject_id == data?.Subject);
      if (unreadMessageCount.length > 0) {
        setThreadUnreadMessages(unreadMessageCount[0]?.unreadMessageCount)
      } else {
        setThreadUnreadMessages(0)
      }
    } else {
      setThreadUnreadMessages(0);
    }

  }, [unReadMessagesRec, data]);


  return (
    <div className="messaging-group-card-view-wrapper" onClick={() => onMessageGroupCardClick()}>
      <div
        style={{ backgroundColor: selectedTabGroup === data?.id ? '#d1d5db' : null }}
        className="messaging-group-card-view-container"
      >
        <div className="messaging-group-card-profile-icon">
          {role() === ROLE_NAME.EXPERT ? (
            <Avatar style={{ marginBottom: '15px' }} size="large" className="pointer">
              {showCustomAvatarName(`${profileData?.first_name} ${profileData?.last_name}`)}
            </Avatar>
          ) : (
            <img
              className="messaging-group-card-profile-icon-img"
              src={profileData?.expert_meta ? profileData?.expert_meta?.avatar : expertIcon}
              alt=""
            />
          )}
        </div>
        <div className="messaging-group-card-user-detail">
          <div className="messaging-group-card-profile-name">
            {' '}
            {profileData?.first_name

              ? breakName(profileData?.first_name, profileData?.last_name)

              :

              '-'}

          </div>
          <div className="messaging-group-card-last-chat">
            {data?.messages[data?.messages?.length - 1]?.user_id === getUserData().id
              ? data?.messages[data?.messages?.length - 1]?.attachment_type
                ? renderPlaceholderBasedOnType(
                  data?.messages[data?.messages?.length - 1]?.attachment_type,
                  'to',
                )
                : `You: ${data?.messages[data?.messages?.length - 1]?.body}`
              : data?.messages[data?.messages?.length - 1]?.attachment_type
                ? renderPlaceholderBasedOnType(
                  data?.messages[data?.messages?.length - 1]?.attachment_type,
                  'from',
                )
                : data?.messages[data?.messages?.length - 1]?.body}
          </div>
        </div>
        <div className="messaging-group-card-user-timestamp-wrapper">
          <div className="messaging-group-card-user-time-stamp-detail">
            <div className="messaging-group-card-time-stamp">
              {/* {formatDateTo(data?.messages[data?.messages?.length - 1]?.created_at, 'hh:mm A')} */}
              {convertTimeToTimezone(data?.messages[data?.messages?.length - 1]?.created_at)}
            </div>
            {data?.messages[data?.messages?.length - 1]?.user_id === getUserData().id ? (
              <div className="messaging-group-card-user-message-indicator" />
            ) : (
              threadUnreadMessages > 0 && (
                <div className="messaging-group-card-unread-message-indicator">
                  {threadUnreadMessages}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
