import Pusher from 'pusher-js';
export const API_URL = process.env.REACT_APP_API_URL; // API base URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const STRIPE_SRCRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY; // API base URL

export const PER_PAGE = 20; // API base URL

export const VERSION_NUMBER = process.env.REACT_APP_VERSION || '0.0.0.1';

export const SALT_KEY = process.env.REACT_APP_JWT_SECRET;

export const ZOOM_MEETING_SDK_KEY = process.env.REACT_APP_ZOOM_SDK_KEY;

export const GET_FILE_URL = "get-file-url";

export const GTE_BASE64_FILE = "get-base64-img";

export const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=";

export const X_SHARE_URL = `https://twitter.com/intent/tweet?url=`;

export const LINKEDIN_SHARE_URL = `https://www.linkedin.com/shareArticle?mini=true&url=`;

export const ROLE_NAME = {
  CUSTOMER: 'customer',
  EXPERT: 'expert',
  ADMIN: 'super_admin',
};

export const CURRENT_ROLE_NAME = 'customer';
export const PASSWORD_RULE =
  '(8 Characters Min. Use combination of upper and lowercase letters, numbers and symbols)';

export const INQUIRY_TYPES = [
  {
    id: 1,
    key: 'Customer Support',
    color: 'Customer Support',
  },
  {
    id: 2,
    key: 'Expert Support',
    color: 'Expert Support',
  },
  {
    id: 3,
    key: 'Technical Support',
    color: 'Technical Support',
  },
  {
    id: 5,
    key: 'Request a Refund',
    color: 'Request a Refund',
  },
  {
    id: 4,
    key: 'Other',
    color: 'Other',
  },
];

export const CUSTOMER_REVIEW = [
  {
    id: 1,
    rate: 5,
    name: 'Robert Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
  {
    id: 2,
    rate: 3,
    name: 'Micheal Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
  {
    id: 3,
    rate: 4,
    name: 'Robert Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
  {
    id: 4,
    rate: 2,
    name: 'Robert Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
  {
    id: 5,
    rate: 3,
    name: 'Robert Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
  {
    id: 6,
    rate: 4,
    name: 'Robert Casas',
    role: 'Senior Payroll',
    review:
      'Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator. as well as a random Lipsum generator.',
    companyName: 'Company Name',
  },
];

export const SOCIAL = {
  facebook: {
    page: 'https://www.facebook.com/profile.php?id=61568643630355',
    ahemad: 'https://facebook.com',
    moataz: 'https://facebook.com',
    abdul: 'https://facebook.com',
  },
  twitter: {
    page: 'https://x.com/Accountizer',
    ahemad: 'https://twitter.com/medo_dedo1986',
    moataz: 'https://twitter.com/MoatazShokry7',
    abdul: 'https://twitter.com/AbdelmageedSay5',
  },
  linkedIn: {
    page: 'https://www.linkedin.com/company/90776444/admin/dashboard/',
    ahemad: 'https://www.linkedin.com/in/ahmed-ibrahim-aa-41657163/',
    moataz: 'https://www.linkedin.com/in/moataz-shokry',
    abdul: 'https://www.linkedin.com/in/abdelmageed-sayed-364342106',
  },
  youtube: {
    page: 'https://www.youtube.com/channel/UCnrgZ5kV3LxxgKo8PvI6KnA',
  },
  tiktok: {
    page: 'https://www.tiktok.com/@accountizer?lang=en',
  },
  email: {
    page: 'admin@accountizer.ca',
    ahemad: 'a.ibrahim@accountizer.ca',
    moataz: 'm.shokry@accountizer.ca',
    abdul: 'abdelmageed.sayed@accountizer.ca',
  },
};

export const OTHER_SUB_CATEGORY_IDS = [63, 64, 65, 71];
export const OTHER_SUB_CATEGORY_IDS_OBJ = {
  1: 63,
  3: 64,
  4: 65,
  5: 71,
};

export const step_Constants = {
  STEP_1: {
    type: 'question-type',
    text: 'Hi there! Why not try us today for expert bookkeeping, precise accounting, comprehensive taxation services, and insightful business financial analysis to organize, manage, and improve your financial  matters?',
    text_box1: 'Consult with a financial professional',
    text_box2: 'Hire a fractional financial professional',
    isRequired: true,
  },
  STEP_2: {
    type: 'customer-type',
    text: 'Are you approaching us today as an individual or on behalf of a business?',
    text_box1: 'Individual',
    text_box2: 'Business',
    isRequired: true,
  },
  STEP_3: {
    type: 'business-type',
    text: 'In which of these business categories does your business activity fall?',
    isRequired: false,
  },
  STEP_3_SUB_CAT: {
    type: 'sub-category',
    text: 'Could you please select the primary activity or main focus of your business?',
    isRequired: false,
  },
  STEP_4: {
    type: 'Invoice',
    text: 'How many invoices do you handle per month?',
    isRequired: false,
  },
  STEP_4_LANGUAGES: {
    type: 'Languages',
    text: 'Are there any other languages you prefer for communication?',
    isRequired: false,
  },
  STEP_5: {
    type: 'location',
    text: 'Could you please tell us where you live or where your business is located?',
    isRequired: false,
  },
  STEP_6: {
    type: 'services',
    text: 'Which of the following services can we assist you with today?',
    isRequired: true,
  },
  STEP_6_SUB_CAT: {
    type: 'services_sub_category',
    text: [
      'Could you please tell us which bookkeeping software you are currently using?',
      '',
      'Which accounting topic are you facing a problem with?',
      'Which business analysis topic do you need help with?',
      'Which tax issue do you need help with today?',
    ],
    icons: {
      bookkeeping: 'Bookkeeping.png',
      payrollmanagement: '',
      accounting: 'accounting.png',
      businessanalysis: 'chart.png',
      taxpreparation: 'staff.png',
    },
    others: [
      'Could you please type here the bookkeeping software you are currently using?',
      '',
      'Could you please type here the accounting topic you need help with?',
      'Could you please type here the Business Analysis topic you need help with?',
      'Could you please type here the tax topic you need help with?',
    ],
    isRequired: false,
  },
  STEP_6_OTHERS: {
    type: 'others',
    text: '',
    isRequired: true,
  },
  STEP_7: {
    type: 'final_question',
    text: 'I’m a retail business owner in Ontario, operating a clothing store and handling 501 1,000 invoices per month using QuickBooks. I need help with ensuring accurate financial management and compliance. What specific bookkeeping services can you provide to meet these needs?',
    isRequired: true,
  },
  icons: {
    bookkeeping: '../../assets/images/questions_icons/Bookkeeping.png',
    payrollmanagement: '../../assets/images/questions_icons/staff.png',
    accounting: '../../assets/images/questions_icons/accounting.png',
    businessanalysis: '../../assets/images/questions_icons/chart.png',
    taxpreparation: '../../assets/images/questions_icons/dollar.png',
  },
};
export const MESSAGE_TYPE = {
  JOIN_MEETING: 'JOIN_MEETING',
  PICK_DATE_AND_TIME: 'PICK_DATE_AND_TIME',
  SCHEDULE_MEETING: 'SCHEDULE_MEETING',
};


export const TIME_ZONE_LIST_API_URL = "http://worldtimeapi.org/api/timezone"

export const timezone = [
  { label: 'America/St_Johns', value: 'America/St_Johns' },
  { label: 'America/Halifax', value: 'America/Halifax' },
  { label: 'America/Toronto', value: 'America/Toronto' },
  { label: 'America/Winnipeg', value: 'America/Winnipeg' },
  { label: 'America/Edmonton', value: 'America/Edmonton' },
  { label: 'America/Vancouver', value: 'America/Vancouver' },
];


export const pusher = new Pusher(process.env.REACT_APP_APP_KEY, {
  cluster: process.env.REACT_APP_APP_CLUSTER,
});

export const nameRecommendationConstantForToolTip = {
  "color": "#4471c4",
  "title": "For privacy reasons, the full name is displayed only after accepting the recommendation"
};

export const whiteBackgroundUrls = ["/", "/about-us", "/expert", "/expert/about-us", "/blogs", "/find-expert", "/find-expert-detail", "/faq", "/expert/faq", "/contact", "/expert/contact"];

