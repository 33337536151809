/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Spin,
  Select,
  DatePicker,
  Button,
  InputNumber,
  message,
} from 'antd';
import CustomSelect from '../customSelect';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;

const QuotationModal = props => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const [form] = Form.useForm();
  const { open, close, questionId, type, questionData, openPayment, setWarningModalVisible } = props;
  const { isQuestionLoading, isCommonUpdateLoading, commonMsg } = useSelector(
    state => state.common,
  );
  const { isBillLoading, billPlanList } = useSelector(state => state.expert);
  useEffect(() => {
    dispatch({ type: 'BILL_PLAN_REQUEST' });
  }, [questionId]);

  useEffect(() => {
    if (
      questionData.quotation_detail !== undefined &&
      questionData.quotation_detail !== null &&
      !isBillLoading &&
      billPlanList.length > 0
    ) {
      if (Object.keys(questionData.quotation_detail).length > 0) {
        form.setFieldsValue({
          title: questionData.quotation_detail?.assignment_title,
          disc: questionData.quotation_detail?.details,
          price: questionData.quotation_detail?.price,
          billing_plan_id: parseInt(questionData.quotation_detail?.billing_plan_id),
          // billing_plan_id: 2,
          delivery_date:
            questionData.quotation_detail.delivery_date !== null
              ? moment(questionData.quotation_detail?.delivery_date)
              : '',
        });
      }
    }
  }, [questionData, isBillLoading, billPlanList]);

  useEffect(() => {
    if (!isCommonUpdateLoading && commonMsg === 'success') {
      dispatch({ type: 'CLEAR_COMMON' });
      close(false);
    }
  }, [isCommonUpdateLoading, commonMsg]);

  const onFinish = values => {
    if (type === 'customer') {
      form
        .validateFields()
        .then(values => {
          openPayment(true);
          // // values.delivery_date = moment(values.delivery_date).format("YYYY-MM-DD");
          // let tmpFormData = {
          //     accepted: 1,
          //     question_id: qid
          // };

          // dispatch({
          //     type: 'COMMON_PUT_POST_REQUEST',
          //     reqType: "put",
          //     module: "accept-quotation",
          //     formData: tmpFormData
          // });
        })
        .catch(errorInfo => { });
    } else if (type === 'expert') {
      form
        .validateFields()
        .then(values => {
          values.delivery_date = moment(new Date(values.delivery_date)).format('YYYY-MM-DD');
          // values.delivery_date = moment(values.delivery_date).format("YYYY-MM-DD");
          values.price = parseInt(values.price);
          dispatch({
            type: 'COMMON_PUT_POST_REQUEST',
            reqType: 'post',
            module: 'reply-quotation',
            formData: values,
          });
        })
        .catch(errorInfo => {
          console.log(errorInfo);

        });
    }
  };

  const onCancel = () => {
    let tmpFormData = {
      accepted: 0,
      question_id: qid,
    };
    message.error('Please send message to expert in order to revise the quotation');
    close(false);
    // dispatch({
    //     type: 'COMMON_PUT_POST_REQUEST',
    //     reqType: "put",
    //     module: "accept-quotation",
    //     formData: tmpFormData
    // });
  };

  const optionsList = tmpLocation => {
    let locationOptions = [];
    if (tmpLocation.length > 0) {
      tmpLocation.map(item => {
        locationOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return locationOptions;
  };

  const selectAfter = (
    <CustomSelect
      placeholder="Select an option"
      size="large"
      defaultValue="CAD"
      style={{ width: 80 }}
    >
      <Option value="CAD">CAD</Option>
    </CustomSelect>
  );

  return (
    <Modal
      title={'Quotation Details'}
      className="payment-modal quotation-modal"
      open={open}
      // onOk={onFinish}
      // header={false}
      footer={false}
      onCancel={() => close(false)}
      okText={'Accept & send quotation'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      width={600}
    >
      {isQuestionLoading ? (
        <Spin />
      ) : (
        <Form layout="vertical" name="basic" form={form} onFinish={onFinish} autoComplete="off">
          <Row gutter={30}>
            <Col xs={24} sm={12} md={24} lg={24} xl={24}>
              <Form.Item style={{ display: 'none' }} name={'question_id'} initialValue={questionId}>
                <Input defaultValue={questionId} />
              </Form.Item>
              <Form.Item
                label="Assignment Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please assigment title',
                  },
                  {
                    max: '100',
                    message: 'Maximum 100 characters are allowed',
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={type !== 'expert' ? true : false}
                  placeholder="Assigment title"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Details of the assignment"
                name="disc"
                rules={[
                  {
                    required: true,
                    message: 'Please enter details',
                  },
                  {
                    max: 5000,
                    message: 'Maximum 5000 characters are allowed',
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  disabled={type !== 'expert' ? true : false}
                  placeholder="Assignment details"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {type !== 'expert' && (
                <Form.Item label={'Price'} name="price">
                  <Input
                    size="large"
                    disabled={qid ? true : false}
                    placeholder={'$CA'}
                    autoComplete="new-state"
                    addonAfter={'$CA'}
                  />
                </Form.Item>
              )}
              {type === 'expert' && (
                <Form.Item
                  label={'Price'}
                  name="price"
                  rules={[
                    { required: true, message: 'Please enter price' },
                    {
                      pattern: new RegExp(/^[0-9]{1,10}$/),
                      message: 'Please enter valid price',
                    },
                  ]}
                >
                  <InputNumber
                    addonAfter={'$CA'}
                    style={{ width: '100%' }}
                    size="large"
                    disabled={type !== 'expert' ? true : false}
                    placeholder={'$CAD'}
                    autoComplete="new-state"
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Billing plan"
                name="billing_plan_id"
                rules={[{ required: true, message: 'Please select location' }]}
              >
                <CustomSelect
                  placeholder="Select an option"
                  style={{
                    width: '100%',
                  }}
                  size="large"
                  showSearch
                  loading={isBillLoading}
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={type !== 'expert' ? true : false}
                  options={optionsList(billPlanList)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Expected delivery date"
                name={'delivery_date'}
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Please select date!',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={current => {
                    let customDate = moment().format('YYYY-MM-DD');
                    return current && current < moment(customDate, 'YYYY-MM-DD');
                  }}
                  disabled={type !== 'expert' ? true : false}
                  style={{ width: '100%' }}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ marginBottom: '10px' }}>
            Please review the Quotation Details carefully. By pressing Accept Quotation, you are
            entering into a Service Contract. The terms are governed by the Client Agreement (for
            clients) or the Service Provider Agreement (for service providers). Each party is
            responsible for reviewing their respective agreement.
          </div>

          {type === 'customer' && (
            <div className="flex-x center">
              <Button key="nocalcel" className="mr15" danger onClick={() => onCancel()}>
                Decline and cancel request
              </Button>
              <Button key="accept" type="primary" onClick={() => onFinish()}>
                Accept quotation
              </Button>
            </div>
          )}
          {type === 'expert' && (
            <div className="flex-x center">
              <Button key="nocalcel" className="mr15" danger onClick={() => close(false)}>
                Cancel
              </Button>
              <Button
                key="accept"
                type="primary"
                loading={isCommonUpdateLoading}
                disabled={isCommonUpdateLoading}
                onClick={() => onFinish()}
              >
                Accept & send quotation
              </Button>
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default QuotationModal;
