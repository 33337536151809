import React, { useEffect, useState } from 'react';
import { Row, Card, Col, Tag, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { formatAmount, formatDateTo, role } from '../../helpers/functions';
import { Link, useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

const PaymentDetails = props => {
  const dispatch = useDispatch();
  const { qid } = useParams();
  const { isPayDetailLoading, paymentData } = useSelector(state => state.expert);
  const [transData, setTranData] = useState([]);

  useEffect(() => {
    getAllPayments({});
  }, []);

  const getAllPayments = (params = {}) => {
    dispatch({ type: 'PAYMENTS_DATA_REQUEST', formData: { question_id: qid } });
  };

  useEffect(() => {
    if (!isPayDetailLoading && paymentData.length > 0) {
      let tmpArray = [];
      paymentData.map(item => {
        let tmpObj = { ...item };
        tmpObj.date = formatDateTo(item?.created_at);
        tmpArray.push(tmpObj);
      });
      setTranData(tmpArray);
    } else if (!isPayDetailLoading && paymentData.length === 0) {
      setTranData([]);
    }
  }, [isPayDetailLoading, paymentData]);
  return (
    <>
      {isPayDetailLoading ? (
        <Card style={{ textAlign: 'center' }} className="mb15">
          <Spin />
        </Card>
      ) : !transData?.length ? (
        <Card style={{ textAlign: 'center' }} className="mb15">
          No payment details found.
        </Card>
      ) : (
        transData?.map(item => {
          return (
            <Card className="mb15">
              <Row>
                <Col
                  style={{ wordWrap: 'break-word', paddingRight: '15px' }}
                  xs={24}
                  sm={24}
                  md={9}
                  lg={9}
                  xl={6}
                >
                  <p className="small-text">Transaction Id</p>
                  <p className="big-text">{item?.stripe_id}</p>
                </Col>

                <Col xs={24} sm={24} md={4} lg={3} xl={4}>
                  <p className="small-text">Date</p>
                  <p className="big-text">{item?.date}</p>
                </Col>

                <Col xs={24} sm={24} md={5} lg={2} xl={5}>
                  <p className="small-text">Total</p>
                  <p className="big-text">{formatAmount(item?.stripe_price)}</p>
                </Col>

                <Col xs={24} sm={24} md={5} lg={3} xl={3}>
                  <p className="small-text">Billing Type</p>
                  <p className="big-text" >{(item?.subscription_id) ? 'Monthly' : 'One Time'}</p>
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                  <p className="small-text">Status</p>
                  <p className="big-text">
                    {item.stripe_status === 'paid' || item.stripe_status === 'succeeded' || item.stripe_status === 'active' ? (
                      <Tag color="green">Succeeded</Tag>
                    ) : (
                      <Tag color="orange">Pending</Tag>
                    )}
                  </p>
                </Col>

                {
                  (role() === 'customer') &&
                  <Col xs={24} sm={24} md={5} lg={2} xl={3} style={{ alignContent: 'center' }}>
                    <Link to={item?.receipt_url || ''} target="_blank"  >
                      <DownloadOutlined style={{ fontSize: '2rem', color: '#1890ff', cursor: 'pointer' }} />
                    </Link>

                  </Col>

                }

              </Row>
            </Card>
          );
        })
      )}
    </>
  );
};

export default PaymentDetails;
