import { Modal, Form, Row, Col, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from '../customSelect';

export const AddCreditToAnyModal = props => {
  const { visible, setVisible, rowData, doRefresh } = props;
  const dispatch = useDispatch();
  const { isExpertListLoading, expertsList } = useSelector(state => state.expert);
  const { isAdminUloading, adminUpdateMsg } = useSelector(state => state.admin);
  const [form] = Form.useForm();
  const [credits, setCredits] = useState(100);

  useEffect(() => {
    dispatch({ type: 'EXPERT_LIST_REQUEST', formData: {} });
  }, []);

  const onFinish = values => {
    form
      .validateFields()
      .then(values => {
        dispatch({
          type: 'ADMIN_ADD_UPDATE_REQUEST',
          reqType: 'post',
          module: 'credit',
          formData: values,
        });
      })
      .catch(errorInfo => {});
  };

  useEffect(() => {
    if (!isAdminUloading && adminUpdateMsg === 'success') {
      dispatch({ type: 'CLEAR_ADMIN_DATA' });
      setVisible(false);
    }
  }, [isAdminUloading, adminUpdateMsg]);

  const optionsList = tmpExpertList => {
    let locationOptions = [];
    if (tmpExpertList.length > 0) {
      tmpExpertList.map(item => {
        return locationOptions.push({
          value: item.id,
          label: `${item?.first_name} ${item?.last_name} `,
        });
      });
    }
    return locationOptions;
  };

  return (
    <Modal
      closable={false}
      centered
      open={visible}
      onOk={onFinish}
      onCancel={() => setVisible(false)}
      width="600px"
      destroyOnClose={true}
      okText={'Add Credit'}
      okButtonProps={{
        disabled: false,
        loading: false,
      }}
      cancelText={'Cancel'}
    >
      <div className="assign-to-expert-modal-container">
        <div className="assign-to-expert-modal-heading">
          {/* Assign Question Id {rowData?.id} to : {selectedExpertDetail?.first_name} {selectedExpertDetail?.last_name} */}
          Add Credit
        </div>
        <Form layout="vertical" name="basic" form={form} autoComplete="off">
          <Row gutter={30}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Expert Name"
                name="user_id"
                rules={[
                  {
                    required: true,
                    message: 'Please select expert',
                  },
                ]}
              >
                <CustomSelect
                  placeholder="Select an option"
                  style={{
                    width: '100%',
                  }}
                  size="large"
                  showSearch
                  allowClear
                  loading={isExpertListLoading}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionsList(expertsList)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={'Credit amount'}
                name="credit_amount"
                rules={[
                  { required: true, message: 'Please enter amount' },
                  {
                    pattern: new RegExp(/^[0-9.]{1,10}$/),
                    message: 'Please enter valid ampunt',
                  },
                ]}
              >
                <InputNumber
                  value={credits}
                  style={{ width: '100%' }}
                  prefix="CAD$"
                  onChange={e => setCredits(e)}
                  maxLength={9}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
