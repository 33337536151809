import React from 'react';
import { MessagesGroupView } from '../common/messagesGroupView';

const width =
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const RequestMessaging = () => {
  return (
    <div style={{ width: (width * 87) / 100 }}>
      <div style={{ marginBottom: '26px' }} className="requests-title">
        Messages
      </div>
      <MessagesGroupView />
    </div>
  );
};
