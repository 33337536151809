import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import Helmet from '../../common/helmet';
import ExpertHeader from '../expertHeader';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import ServiceType from './serviceType';
import CustomerType from './customerType';
import MainCategory from './mainCategory';
import Certification from './certification';
import Location from './location';
import Languages from './languages';
import Experience from './experience';
import ServicesAndRates from './servicesAndRates';
import UniversityDegree from './universityDegree';

const ExpertProfile = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('customer-type');
  const { eid } = useParams();
  const [searchParams] = useSearchParams();
  const [percentage, setPercentage] = useState(0);
  const totalSteps = 7;

  useEffect(() => {
    let tmpType = searchParams.get('type');
    if (tmpType !== null) {
      setType(tmpType);
      if (tmpType !== '') {
        if (tmpType === 'main-category') {
          calculatePercentage(1, totalSteps);
        } else if (tmpType === 'mainSubCategories') {
          calculatePercentage(2, totalSteps);
        } else if (tmpType === 'degree') {
          calculatePercentage(3, totalSteps);
        } else if (tmpType === 'certification') {
          calculatePercentage(4, totalSteps);
        } else if (tmpType === 'location') {
          calculatePercentage(5, totalSteps);
        } else if (tmpType === 'language') {
          calculatePercentage(6, totalSteps);
        } else if (tmpType === 'experience') {
          calculatePercentage(7, totalSteps);
        }
      }
    }
  }, [searchParams]);

  const handleNext = (stepType = '') => {
    if (stepType !== '') {
      if (stepType === 'main-category') {
        navigate(`/expert/profile/${eid ?? ''}?type=main-category`);
      } else if (stepType === 'degree') {
        navigate(`/expert/profile/${eid ?? ''}?type=degree`);
      } else if (stepType === 'certification') {
        navigate(`/expert/profile/${eid ?? ''}?type=certification`);
      } else if (stepType === 'location') {
        navigate(`/expert/profile/${eid ?? ''}?type=location`);
      } else if (stepType === 'language') {
        navigate(`/expert/profile/${eid ?? ''}?type=language`);
      } else if (stepType === 'experience') {
        navigate(`/expert/profile/${eid ?? ''}?type=experience`);
      } else if (stepType === 'mainSubCategories') {
        navigate(`/expert/profile/${eid ?? ''}?type=mainSubCategories`);
      }
    }
  };

  const calculatePercentage = (step, totalStep = 8) => {
    let tmpPercentage = (100 * step) / totalStep;
    setPercentage(tmpPercentage);
  };

  const handleBack = redirectType => {
    if (redirectType === 'main-category') {
      navigate(`/expert/profile/${eid ?? ''}?type=customer-type`);
    } else if (redirectType === 'degree') {
      navigate(`/expert/profile/${eid ?? ''}?type=mainSubCategories`);
    } else if (redirectType === 'certification') {
      navigate(`/expert/profile/${eid ?? ''}?type=degree`);
    } else if (redirectType === 'location') {
      navigate(`/expert/profile/${eid ?? ''}?type=certification`);
    } else if (redirectType === 'language') {
      navigate(`/expert/profile/${eid ?? ''}?type=location`);
    } else if (redirectType === 'experience') {
      navigate(`/expert/profile/${eid ?? ''}?type=language`);
    } else if (redirectType === 'mainSubCategories') {
      // navigate(`/expert/profile/${eid ?? ''}?type=experience`);
      navigate(`/expert/profile/${eid ?? ''}?type=main-category`);
    }
  };

  const displayStep = () => {
    if (type !== '') {
      if (type === 'service-type') {
        return <ServiceType handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'customer-type') {
        return <CustomerType handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'main-category') {
        return <MainCategory handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'degree') {
        return <UniversityDegree handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'certification') {
        return <Certification handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'location') {
        return <Location handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'language') {
        return <Languages handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'experience') {
        return <Experience handleNext={handleNext} handleBack={handleBack} step={type} />;
      } else if (type === 'mainSubCategories') {
        return <ServicesAndRates handleNext={handleNext} handleBack={handleBack} step={type} />;
      }
    } else {
      if (type === 'service-type') {
        return <ServiceType handleNext={handleNext} handleBack={handleBack} step={type} />;
      }
    }
  };

  return (
    <div className="question-create">
      <Helmet title="Update Profile" />
      <ExpertHeader />
      <Progress percent={percentage} strokeLinecap="butt" showInfo={false} />
      <div className="profile d-flex-a-c mt30">{displayStep()}</div>
    </div>
  );
};

export default ExpertProfile;
