import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Avatar, Col, Divider, message, Popconfirm, Row, Spin } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { formatDateBlog } from '../../helpers/functions';
import { use } from 'marked';
import { debounce } from 'lodash';
const Blogs = ({ admin, keys }) => {

  const blogs = useSelector(state => state?.common?.Blogs);
  const isBlogsLoading = useSelector(state => state?.common?.isBlogsLoading);
  const isDeleteBlog = useSelector(state => state?.common?.isDeleteBlogLoading);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreBlogs, setHasMoreBlogs] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'GET_BLOGS', page: page });
  }, [page]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop + clientHeight) / scrollHeight * 100;
      if (scrollPercentage >= 60 && !isBlogsLoading && hasMoreBlogs) {
        setPage(prevPage => prevPage + 1);
      }
    }, 500);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isBlogsLoading, hasMoreBlogs]);


  useEffect(() => {
    if (keys == '1') {
      dispatch({ type: 'GET_BLOGS', page: 1 });
    }
  }, [keys]);


  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length > 3) {
      dispatch({ type: 'GET_BLOGS', search: search });
    }
    if (search.length === 0) {
      dispatch({ type: 'GET_BLOGS', page: 1 });
    }
  }, [search]);

  useEffect(() => {
    if (!isDeleteBlog && deleteLoading) {
      setDeleteLoading(false);
      message.success('Blog deleted successfully');
      dispatch({ type: 'GET_BLOGS' });
    }
    if (isDeleteBlog && deleteLoading) {
      setDeleteLoading(false);
      message.error('Failed to delete blog');
    }
  }, [isDeleteBlog]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <div class="image-main-container " style={{ width: admin ? '90%' : '100%' }}>
        {/* {!admin && <h1 style={{ marginTop: '1rem', color: 'rgba(28,100,242,.9)' }}>Blogs</h1>} */}
        {
          <input
            type="text"
            onChange={e => setSearch(e.target.value)}
            placeholder="Search for blogs"
            style={{
              border: '1px solid rgba(28,100,242,.9)',
              borderRadius: '8px',
              outline: 'none',
              padding: '10px 10px',
              margin: '1rem 0rem',
            }}
          />
        }
        {isBlogsLoading && (
          <Spin
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}
        <div class="grid-container">
          {blogs?.length
            ? blogs.map((data, index) => {
              return (
                <>
                  <div >
                    <div
                      onClick={() =>
                        navigate(admin ? `/admin/blogs/${data?.id}` : `/blogs/${data?.id}`, {
                          state: data?.id,
                        })
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <img
                        // src={`https://dummyimage.com/{${index%2!=0?'600x800':'600x400'}/d4d4d4/454545&text=Deepanshu`}

                        src={data?.featured_image}
                        alt="Blog"
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                          marginTop: '1rem',

                        }}
                      >
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                          {data?.author_image && <span>
                            <Avatar size="large" style={{ backgroundColor: 'transparent' }} >
                              <img src={data?.author_image} alt="avatar" />
                            </Avatar>
                          </span>}
                          {data?.author_name && <strong>{data?.author_name}</strong>}
                          <span style={{ color: '#6b7280' }} >{formatDateBlog(data?.created_at)}</span>
                         { admin && <span style={{ color: (data?.published) ? 'green' : 'red' }} >{(data?.published)?'Published':'Unpublished'}</span>}
                        </div>
                        <strong style={{ fontSize: '1.5rem', }}>{data?.title}</strong>
                        <span style={{ marginTop: '1rem' }}>Read More {'>'}</span>
                      </div>

                      {admin && (
                        <Popconfirm
                          onConfirm={e => {
                            e.stopPropagation();
                            setDeleteLoading(true);
                            dispatch({ type: 'DELETE_BLOG', id: data?.id });
                          }}
                          onCancel={e => {
                            e.stopPropagation();
                          }}
                          title="Are you sure to delete this blog?"
                        >
                          <DeleteFilled
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            style={{ color: 'red', fontSize: '1.5rem', cursor: 'pointer' }}
                          />
                        </Popconfirm>
                      )}
                      {admin && (
                        <EditFilled
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/admin/edit-blog/${data?.id}`,
                              {
                                state: data?.id,
                              });
                          }}
                          style={{ marginLeft: '2rem', color: 'rgba(28,100,242,.9)', fontSize: '1.5rem', cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            })
            : !isBlogsLoading &&
            <div class="grid-container" style={{ columns: 'unset' }}>
              <div style={{ minHeight: '40vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h1>No Blogs Found</h1>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Blogs;
