import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, message, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar } from 'react-simple-wysiwyg';

const EditBlog = () => {
  const location = useLocation();
  const [blog, setBlog] = useState({});
  const blogFromRedux = useSelector(state => state?.common?.singleBlog);
  const isEditBlogLoading = useSelector(state => state?.common?.isEditBlogLoading);
  const [form] = Form.useForm();
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorImage, setAuthorImage] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      dispatch({ type: 'GET_SINGLE_BLOG', id: location.state });
    }
  }, [location.state]);


  useEffect(() => {
    setBlog(blogFromRedux);
  }, [blogFromRedux]);

  const handleImageUpload = ({ fileList }) => {
    setImage(fileList);
    const file = fileList[0];
    if (file) {
      form.setFieldValue('featured_image', file.originFileObj);
    } else {
      form.setFieldValue('featured_image', null);
    }
  };

  const handleAuthorImagehandle = ({ fileList }) => {
    setAuthorImage(fileList);
    const file = fileList[0];
    if (file) {
      form.setFieldValue('author_image', file.originFileObj);
    } else {
      form.setFieldValue('author_image', null);
    }
  };

  const handleSubmit = values => {
    if (content === '') {
      message.error('Please provide body');
      return;
    }
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('body', content);
    formData.append('featured_image', values.featured_image);
    formData.append('published', values.published);
    formData.append('slug', values.title + Date.now());
    formData.append('author_id', 1);
    formData.append('excerpt', 'excerpt');
    formData.append('featured_image_caption', values.featured_image_caption);
    formData.append('_method', 'put');
    formData.append('author_image', values.author_image);
    formData.append('author_name', values.author_name);
    dispatch({ type: 'EDIT_BLOG', formData: formData, id: location.state });
    setLoading(true);
  };

  useEffect(() => {
    if (!isEditBlogLoading && loading) {
      setLoading(false);
      message.success('Blog edited successfully');
      dispatch({ type: 'EDIT_BLOG_ERROR' });
    }
  }, [isEditBlogLoading])

  useEffect(() => {
    if (blog) {
      form.setFieldsValue({
        title: blog?.title,
        published: blog?.published,
        featured_image_caption: blog?.featured_image_caption,
        author_name: blog?.author_name,
        author_image: blog?.author_image,
        featured_image: blog?.featured_image
      });
      setContent(blog?.body);
      setLoading(false);
      setAuthorImage([{
        uid: '-1',
        status: 'done',
        url: blog?.author_image,
      }])
      setImage([{
        uid: '-1',
        status: 'done',
        url: blog?.featured_image,
      }])
    }
  }, [blog, form]);

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="horizontal" style={{ maxWidth: 900 }}>
        <Form.Item
          label="Title"
          name="title" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Please enter title',
            },
          ]}
        >
          <Input placeholder="Title" defaultValue={blog?.title} />
        </Form.Item>
        <EditorProvider>
          <Editor
            containerProps={{ style: { height: 300, resize: 'vertical' } }}
            value={content}
            onChange={e => setContent(e.target.value)}
          >
            <Toolbar>
              <BtnBold />
              <BtnItalic />
            </Toolbar>
          </Editor>
        </EditorProvider>
        <Form.Item label="Published" name="published" valuePropName="checked">
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item
          label="Upload Image"
          name={'featured_image'}
          rules={[
            {
              required: true,
              message: 'Please upload image',
            },
          ]}
        >
          <Upload
            name="featured_image"
            onChange={handleImageUpload}
            maxCount={1}
            listType="picture-card"
            fileList={image}
          >
            {image?.length < 1 && (<div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>)}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Featured Image Caption"
          name="featured_image_caption" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Featured Image Caption',
            },
          ]}
        >
          <Input placeholder="Featured Image Caption" />
        </Form.Item>
        <Form.Item
          label="Author Name"
          name="author_name" // Add name prop here
          rules={[
            {
              required: true,
              message: 'Please enter author name',
            },
          ]}

        >
          <Input placeholder="Author Name" />
        </Form.Item>
        <Form.Item
          label="Upload Author Image"
          name={'author_image'}
          rules={[
            {
              required: true,
              message: 'Please upload author image',
            },
          ]}
        >
          <Upload
            name="author_image"
            onChange={handleAuthorImagehandle}
            fileList={authorImage}
            maxCount={1}
            listType="picture-card"
          >
            {authorImage?.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item>
          {
            loading ?
              <Spin />
              :
              <Button type="primary" htmlType="submit" className="w100 mt25" size="large">
                Update
              </Button>
          }
        </Form.Item>
      </Form>
    </>
  );
};

export default EditBlog;
