import React, { useState, useEffect } from 'react';
import { Layout, Button, Avatar, Popover } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MainLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import WhiteLogo from '../../assets/images/Acc_logo/logo_blue.svg';
import { getFullName, getUserData, isLogin, logout, showAvatarName } from '../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
const { Header } = Layout;

const items = [
  {
    label: 'About Accountizer',
    key: 'expert/about-us',
  },
  {
    label: 'Blogs',
    key: 'blogs',
  },
  {
    label: 'FAQs',
    key: 'expert/faq',
  },
  {
    label: 'Contact',
    key: 'expert/contact',
  },
  {
    label: 'Book A Demo',
    key: 'https://calendly.com/justin-m-accountizer',
  },
];

const ExpertHeader = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState('mail');


  const dispatch = useDispatch();
  const { expertDetailById, isExpertDetailLoading } = useSelector(state => state.requests);

  useEffect(() => {
    if (isLogin()) {
      dispatch({ type: 'GET_EXPERT_DETAIL_BY_ID', id: getUserData().id });
    }
  }, [expertDetailById?.avatar]);

  const onClick = e => {
    if (
      e.key === 'expert/about-us' ||
      e.key === 'expert/contact' ||
      e.key === 'find-expert' ||
      e.key === 'blogs' ||
      e.key === 'expert/faq'
    ) {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    } else if (e.key === 'https://calendly.com/justin-m-accountizer') {
      window.open(e.key, '_blank');
      setCurrent('');
    } else {
      setCurrent('');
    }
  };

  const onLogoutClick = () => {
    logout();
    navigate('/');
  };

  const content = (
    <div>
      <p>{getFullName()}</p>
      <p>
        <span className="header-menu pointer" onClick={() => navigate('/dashboard/expert/request')}>
          Dashboard
        </span>
      </p>
      <p>
        <span className="header-menu pointer" onClick={() => onLogoutClick()}>
          Logout
        </span>
      </p>
    </div>
  );

  useEffect(() => {
    const element = document.getElementById("my-header");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.pathname]);
  return (
    <>
      <Header className={`header ${location.pathname === '/expert' ? 'transfer' : ''}`}>
        <div className="d-flex-a-c" style={{ width: '100%', justifyContent: 'space-between' }}>
          <div id='my-header'>
            <div className="main-logo">
              {location.pathname === '/expert' ? (
                <img
                  src={WhiteLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer"
                  style={{ width: "15vw" }}
                  alt="Accountizer Logo "
                ></img>
              ) : (
                <img
                  src={MainLogo}
                  onClick={() => navigate('/expert')}
                  className="pointer"
                  alt="Accountizer Logo "
                  style={{ width: "15vw" }}
                ></img>
              )}
            </div>
          </div>
          <div className="d-flex-a-c">
            <div className="header-menu public-header d-flex-a-c">
              <div className="d-flex-a-c">
                {items.map(item => {
                  return (
                    <>
                      <p className="mb0" onClick={e => onClick(item)}>
                        <span className="cursor-p">{item.label}</span>
                      </p>
                    </>

                  );
                })}
              </div>
            </div>
          </div>
          <div>
            {isLogin() && (
              <div className="f-flex-a-e">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mr15"
                  size="middle"
                  onClick={() => navigate('/')}
                >
                  Client Area
                </Button>

                <div className="header-menu d-flex-a-center">
                  <Popover placement="bottom" content={content} trigger="hover" zIndex={99999}>
                    {expertDetailById?.avatar ? (
                      <img
                        style={{ height: '40px', width: '40px', borderRadius: '50%' }}
                        src={expertDetailById?.avatar}
                        alt=""
                      />
                    ) : (
                      <Avatar size="large" className="pointer">
                        {showAvatarName()}
                      </Avatar>
                    )}
                  </Popover>
                </div>
              </div>
            )}
            {!isLogin() && (
              <div className="f-flex-a-e">
                <p className="mb0 mr15 pointer" onClick={() => navigate('/login?type=expert')}>
                  Expert Login
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-r"
                  size="middle"
                  onClick={() => navigate('/')}
                >
                  Client Area
                </Button>
              </div>
            )}
          </div>
        </div>
      </Header>
    </>
  );
};

export default ExpertHeader;
