import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Circleprogress = ({ percentage, heading, info }) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPercentage(percentage ?? 0);
    }, 100);

    return () => {
      clearTimeout(timer);
      setCurrentPercentage(0);
    };
  }, [percentage]);
  const content = (
    <Row style={{ width: '100%', justifyContent: 'space-evenly' }} >
      <Col
        span={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          border: '1px dashed #807e7e',
         
          // padding: '1rem',
          borderRadius: '.5rem',
        }}
      >
       
        <div style={{ width: '100%',backgroundColor: 'rgb(227, 227, 227)',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        {/* <p>Perfect</p> */}
        <span>Perfect</span>
        </div>
       
        <div style={{ width: '100%',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        <span>100%-70%</span>
        </div>
        <div style={{ width: '100%',padding: '1rem' ,}}>
        <span >{info["perfect"]}</span>
        </div>
      
      </Col>
      <Col
        span={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          border: '1px dashed #807e7e',
          
          // padding: '1rem',
          borderRadius: '.5rem',
        }}
      >
       
        <div style={{ width: '100%',backgroundColor: 'rgb(227, 227, 227)',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        {/* <p>Perfect</p> */}
        <span>Moderate</span>
        </div>
       
        <div style={{ width: '100%',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        <span>69%-35%</span>
        </div>
        <div style={{ width: '100%',padding: '1rem' ,}}>
        <span >{info["moderate"]}</span>
        </div>
      
      </Col>
      <Col
        span={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          border: '1px dashed #807e7e',
          
          // padding: '1rem',
          borderRadius: '.5rem',
        }}
      >
       
        <div style={{ width: '100%',backgroundColor: 'rgb(227, 227, 227)',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        {/* <p>Perfect</p> */}
        <span>Unsure</span>
        </div>
       
        <div style={{ width: '100%',borderBottom: '1px dashed #807e7e',padding: '1rem 0rem' ,textAlign: 'center' }}>
        <span>34%-0%</span>
        </div>
        <div style={{ width: '100%',padding: '1rem' ,}}>
        <span >{info["unsure"]}</span>
        </div>
      
      </Col>
    </Row>
  );
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '10rem',
          width: '15%',
          padding: '.4rem',
          width: '100%',
        }}
      >
        <span style={{ marginBottom: '1rem', fontSize: '0.8rem' }}>
          {heading} <br />
          <Popover content={content} style={{ width: '25rem !important' }} title={heading} trigger="click">
            <InfoCircleOutlined style={{ cursor: 'pointer' }} />
          </Popover>
        </span>

        <CircularProgressbar
          value={currentPercentage ?? 0}  
          text={Math.round(percentage ?? 0) + '%'}
          styles={buildStyles({
            textColor: ' rgb(64,113,202)',
            pathColor: ' rgb(64,113,202)',
          })}
          strokeWidth={10}
        />
      </div>
    </>
  );
};

export default Circleprogress;
