import { Col, Row, Popover, Button, message, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { breakName, convertDateToTimezone, convertTimeToTimezone, formatDateTo, getSubCategoryName } from '../../helpers/functions';
import QuotationModal from './modal/quatationModal';
import QuestionReplyModal from './modal/questionReplyModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactLinkify from 'react-linkify';
import { RequestRejectModal } from './modal/requestRejectModal';
import { nameRecommendationConstantForToolTip } from '../../helpers/constant';


const ExpertRequestCard = props => {

  const [open, setOpen] = useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();
  const [loading, setLoading] = useState(false);


  const [quotationModal, setQuotationModal] = useState(false);
  const [showRequestRejectModal, setShowRequestRejectModal] = useState(false);
  const { acceptOrRejectExpertRequestStatus, isAcceptOrRejectExpertRequestLoading } = useSelector(
    state => state.requests,
  );
  const currentLocation = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showQuotationModal = () => {
    setShowPopover(false);
    setQuotationModal(true);
  };

  const content = (
    <div className="status-popover-content-container">
      <div className="status-popover-inner-container">
        <div
          onClick={() => {
            dispatch({
              type: 'UPDATE_QUESTION_STATUS',
              formData: { question_id: props?.id, question_status_id: 10 },
            });
            if (props?.navigateBackOnCancel) {
              navigate('/dashboard/expert/request');
            }
          }}
          className="status-popover-cancel-button"
        >
          Cancel Request
        </div>
      </div>
    </div>
  );

  const handleMarkAsComplete = data => {
    dispatch({
      type: 'UPDATE_QUESTION_STATUS',
      formData: { question_id: data?.id, question_status_id: 11 },
    });
    if (props?.navigateBackOnCancel) {
      navigate('/dashboard/expert/request');
    }
  };

  const onAcceptNewRequest = id => {
    setLoading(true);
    dispatch({
      type: 'POST_ACCEPT_OR_REJECT_EXPERT_REQUEST',
      formData: { question_id: id, accepted: 1 },
    });
    if (props?.navigateBackOnCancel) {
      navigate('/dashboard/expert/request');
    }
  };


  const getDynamicView = type => {
    switch (type) {
      case 'NewRequest':
        return (
          <Col span={10} className="requests-history-accounting-col">
            {props?.status?.question_status?.id === 3 ? (
              <div className="f-flex-a-e expert-request-button-group">
                {/* Hide messaging button when not in details view */}
                {!props.isClickable && (
                  <Button
                    className="expert-request-button"
                    type="primary"
                    onClick={() => props?.setSelectedTab('messaging')}
                  >
                    Messaging
                  </Button>
                )}
                <Tooltip
                  title={
                    props?.assign_by_admin === 1 &&
                    props?.expert_approved === 0 &&
                    'Waiting on client approval'
                  }
                >
                  <Button
                    className="expert-request-button"
                    type={
                      props?.assign_by_admin === 1 && props?.expert_approved === 0
                        ? 'default'
                        : 'primary'
                    }
                    loading={loading}
                    disabled={props?.assign_by_admin === 1 && props?.expert_approved === 0}
                    onClick={() => {
                      if (props?.expert_approved === 1) {
                        onAcceptNewRequest(props.id);
                        props?.setTabNameAndKey(1)
                      } else {
                        message.error('Waiting for client approval.');
                      }
                    }}
                  >
                    Accept
                  </Button>

                </Tooltip>
                {/* <Button onClick={()=>{props?.setTabNameAndKey(1)}}>accept</Button> */}
                <Tooltip
                  title={
                    props?.assign_by_admin === 1 &&
                    props?.expert_approved === 0 &&
                    'Waiting on client approval'
                  }
                >
                  <Button
                    className="expert-request-button"
                    type="default"
                    loading={loading}
                    disabled={props?.assign_by_admin === 1 && props?.expert_approved === 0}
                    onClick={() => {
                      if (props?.expert_approved === 1) {
                        setShowRequestRejectModal(true);
                      } else {
                        message.error('Waiting for client approval.');
                      }
                    }}
                  >
                    Reject
                  </Button>
                </Tooltip>
                {props.isClickable && (
                  <Button className="expert-request-button" onClick={props.onClick}>
                    View Details
                  </Button>
                )}
              </div>
            ) : null}
          </Col>
        );
      case 'ActiveRequest':
        return getDynamicActionView();
      case 'RequestHistory':
        return (
          <>
            <Col span={6} className="requests-history-accounting-col">
              <div className="status">
                <div className="date">
                  <div className="p-title">Completed on </div>
                  <h3 className="title-date title">
                    {props?.status?.updated_at
                      ? convertDateToTimezone(props?.status?.updated_at, 'MMM DD, YYYY')
                      : '-'}
                    <br />
                    {props?.status?.updated_at
                      ? convertTimeToTimezone(props?.status?.updated_at, 'MMM DD, YYYY')
                      : '-'}
                  </h3>
                </div>
              </div>
            </Col>

            {props.isClickable && (
              <Col span={4}>
                <div className="f-flex-a-e expert-request-button-group">
                  <Button className="expert-request-button" onClick={props.onClick}>
                    View Details
                  </Button>
                </div>
              </Col>
            )}
          </>
        );
      case 'MarkAsComplete':
        return (
          <>
            {props?.status?.question_status?.id > 7 && props?.status?.question_status?.id < 10 && (
              <Col span={10} className="requests-history-accounting-col">
                <div className="f-flex-a-e expert-request-button-group">
                  <Button
                    style={{ minWidth: '160px' }}
                    className="expert-request-button"
                    onClick={() => handleMarkAsComplete(props)}
                    type="primary"
                  >
                    Mark as completed
                  </Button>
                  {props.isClickable && (
                    <Button className="expert-request-button" onClick={props.onClick}>
                      View Details
                    </Button>
                  )}
                  {popoverMenu()}
                </div>
              </Col>
            )}
          </>
        );
      case 'QuestionCard':
        return (
          <Col span={10} className="requests-history-accounting-col">
            <div className="f-flex-a-e expert-request-button-group">
              {/* <Button className="expert-request-button" type="default" onClick={props?.onRejectRequestClick ? props?.onRejectRequestClick : null}>Reject</Button> */}
              <Button
                className="expert-request-button"
                onClick={() => {
                  setSelectedRowData(props);
                  setOpen(true);
                }}
              >
                View Question
              </Button>
            </div>
          </Col>
        );
      default:
        return;
    }
  };

  const popoverMenu = () => (
    <Popover
      placement="bottomLeft"
      content={content}
      trigger="click"
      open={showPopover}
      showArrow={false}
      onOpenChange={visible => setShowPopover(visible)}
    >
      <div className="status-popover-option-icon">
        <HiOutlineDotsVertical size={32} />
      </div>
    </Popover>
  );

  const getDynamicActionView = () => {
    if (props?.status?.question_status?.id > 7 && props?.status?.question_status?.id < 10) {
      return (
        <Col span={10} className="requests-history-accounting-col">
          <div className="f-flex-a-e expert-request-button-group">
            <Button
              style={{ minWidth: '160px' }}
              className="expert-request-button"
              type="primary"
              onClick={() => handleMarkAsComplete(props)}
            >
              Mark as completed
            </Button>
            {props.isClickable && (
              <Button className="expert-request-button" onClick={props.onClick}>
                View Details
              </Button>
            )}
            {popoverMenu()}
          </div>
        </Col>
      );
    } else if ([4, 5].includes(props?.status?.question_status?.id)) {
      return (
        <Col span={10} className="requests-history-accounting-col">
          <div className="f-flex-a-e expert-request-button-group">
            <Button
              style={{ minWidth: '160px' }}
              className="expert-request-button"
              type="primary"
              onClick={() => showQuotationModal()}
            >
              Send a quotation
            </Button>
            {props.isClickable && (
              <Button className="expert-request-button" onClick={props.onClick}>
                View Details
              </Button>
            )}
            {popoverMenu()}
          </div>
        </Col>
      );
    } else {
      return (
        <Col span={10} className="requests-history-accounting-col">
          <div className="status f-flex-a-s-j-e">
            <div className="date">
              <div className="p-title">Started on</div>
              <h3 className="title-date title">
                {props?.status?.created_at
                  ? <>{convertDateToTimezone(props?.status?.created_at)} <br /> {convertTimeToTimezone(props?.status?.created_at)}</>
                  : '-'}
              </h3>
            </div>
          </div>
          {popoverMenu()}
        </Col>
      );
    }
  };


  return (
    <div className="requests-history-accounting">
      <Row className="requests-history-accounting-row">
        <Col
          onClick={props.isClickable ? props.onClick : undefined}
          className="requests-history-accounting-col"
          style={{ cursor: props.isClickable ? 'pointer' : 'default' }}
          span={5}
        >
          {props.type === 'QuestionCard' ? (
            <div className="question-card-description pr-4">
              <ReactLinkify>
                {props?.question_disc?.length >= 140
                  ? props?.question_disc?.substring(0, 140) + '...'
                  : props?.question_disc}
              </ReactLinkify>
            </div>
          ) : (
            <div className="id pr-4">
              <div className="p-title">ID #{props.id}</div>
              <h3 className="title">{props?.category?.name ? props?.category?.name : '-'}</h3>
              <p
                style={{
                  fontSize: getSubCategoryName(props)?.length < 50 ? '16px' : '14px',
                  lineHeight: getSubCategoryName(props)?.length < 45 ? '24px' : '16px',
                }}
                className="title-i"
              >
                <i>{getSubCategoryName(props)}</i>
              </p>
            </div>
          )}
        </Col>
        <Col span={(props.type === 'QuestionCard') ? 3 : 5}>
          <div className="date">
            <div className="p-title">Client Name</div>
            <h3 className="title-date title">
              <div style={{ marginTop: '20px', fontWeight: 'bold' }}>
                {
                  (props?.status?.question_status?.status_order >= 7)
                    ?
                    props?.user?.first_name + ' ' + props?.user?.last_name
                    :
                    <Tooltip title={nameRecommendationConstantForToolTip?.title || ""} color={nameRecommendationConstantForToolTip?.color}>
                      {breakName(props?.user?.first_name, props?.user?.last_name)}
                    </Tooltip>
                }
              </div>
            </h3>
          </div>
        </Col>
        {
          (props.type === 'QuestionCard') && <Col span={2}>
            <div className="date">
              <div className="p-title"># ID </div>
              <h3 className="title-date title" style={{ marginTop: '20px', fontWeight: 'bold' }}>
                {props?.id || '-'}
              </h3>
            </div>
          </Col>}
        <Col span={4}>
          <div className="date">
            <div className="p-title">Request date</div>
            <h3 className="title-date title">{convertDateToTimezone(props?.created_at)}</h3>
            <h3 className="title-date title">{convertTimeToTimezone(props?.created_at)}</h3>
          </div>
        </Col>
        {getDynamicView(props.type)}
      </Row>

      <QuestionReplyModal
        open={open}
        close={() => setOpen(false)}
        questionId={props.id}
        rawData={selectedRowData}
        setShowRequestRejectModal={setShowRequestRejectModal}
      />

      {quotationModal && (
        <QuotationModal
          type="expert"
          open={quotationModal}
          close={() => setQuotationModal(false)}
          questionId={props.id}
          questionData={props}
          onRejectRequest={props?.onRejectRequestClick}

        />
      )}

      <RequestRejectModal
        visible={showRequestRejectModal}
        setShowModal={setShowRequestRejectModal}
        rawData={props}
        variant="expert"
      />
    </div>
  );
};

export default ExpertRequestCard;
