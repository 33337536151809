import React, { useEffect, useState } from 'react';
import Helmet from '../common/helmet';
import { Card, Form, Button, Input, Row, Col, Select, message } from 'antd';
import { INQUIRY_TYPES, SOCIAL } from '../../helpers/constant';
import MailIcon from '../../assets/images/mail.svg';
import GloabIcon from '../../assets/images/gloab.svg';
import { AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai';
import { RiFacebookFill } from 'react-icons/ri';
import { useSelector, useDispatch } from 'react-redux';
import CustomSelect from '../common/customSelect';

const { Option } = Select;
const { TextArea } = Input;
const Contact = props => {
  const { isReqLoading, resStatus } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = values => {
    dispatch({ type: 'POST_COMMON_REQUEST', formData: values, moduleType: 'contact' });
  };

  useEffect(() => {
    if (!isReqLoading && resStatus === 'success') {
      dispatch({ type: 'CLEAR_COMMON' });
      form.resetFields();
      message.success(
        'We have received your message and would like to thank you for writing to us.',
      );
    }
  }, [isReqLoading, resStatus]);

  return (
    <div className="contact">
      <Helmet title="Contact" />
      <div className="cover-img">
        <div className="d-flex-a-c">
          <div className="site-card-border-less-wrapper card-wrapper">
            <p className="tc title">Have a question?</p>
            <p className="tc sub-title mb85">or having issues? feel free to contact us anytime.</p>
            <Card bordered={false}>
              <Form
                layout="vertical"
                name="basic"
                hideRequiredMark={true}
                onFinish={onFinish}
                form={form}
                autoComplete="off"
              >
                <Row gutter={30}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Full Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter full name',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Full Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Inquiry type"
                      name="inquiry_type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select type',
                        },
                      ]}
                    >
                      <CustomSelect
                        placeholder="Select an option"
                        size="large"
                        autoComplete="new-state"
                      >
                        {INQUIRY_TYPES.map(item => (
                          <Option key={item.item} value={item.key}>
                            {item.title}
                          </Option>
                        ))}
                      </CustomSelect>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter email',
                    },
                    {
                      type: 'email',
                      message: 'Please enter valid email',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="mb10"
                  label="Your message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your message ',
                    },
                    {
                      max: 1000,
                      message: 'Maximum 1000 characters are allowed',
                    },
                  ]}
                >
                  <TextArea
                    rows={6}
                    maxLength={1000}
                    size="large"
                    placeholder="Your message here..."
                  />
                </Form.Item>
                <p className="tc-text">
                  By submitting this form you agree to our terms and conditions and our Privacy
                  Policy which explains how we may collect, use and disclose your personal
                  information including to third parties.
                </p>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className=""
                    size="large"
                    loading={isReqLoading}
                    disabled={isReqLoading}
                  >
                    Send Message
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
        <div className="email-secion">
          <div className="d-flex-between">
            <div className="section1">
              <img src={MailIcon} alt="Mail"></img>
              <p className="line1">Email us:</p>
              <p className="line2">
                Email us for general queries, including marketing and partnership opportunities.
              </p>
              <p className="line3">admin@accountizer.ca</p>
            </div>
            <div className="section1">
              <img src={GloabIcon} alt="Gloab"></img>
              <p className="line1">Follow us on:</p>
              <p>
                <span className="mr10">
                  <AiOutlineTwitter
                    onClick={() => window.open(SOCIAL?.twitter?.page, '_blank')}
                    className="cursor-p"
                    size={22}
                    color="#6B7280"
                  />
                </span>
                <span className="mr10">
                  <RiFacebookFill
                    onClick={() => window.open(SOCIAL?.facebook?.page, '_blank')}
                    className="cursor-p"
                    size={22}
                    color="#6B7280"
                  />
                </span>
                <span>
                  <AiFillLinkedin
                    onClick={() => window.open(SOCIAL?.linkedIn?.page, '_blank')}
                    className="cursor-p"
                    size={22}
                    color="#6B7280"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
