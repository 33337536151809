import React, { useState, useRef, useEffect } from 'react';
import { Card, Form, Button, Spin, Input, Row, Col, DatePicker, Divider, message, Popconfirm, Modal } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserData } from '../../../helpers/functions';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { AddAnotherCard } from '../../common/addAnotherCard';
import warningImg from '../../../assets/images/warningImg.jpeg';
import Cookies from 'js-cookie';
import WarningModal from '../../common/modal/WarningModal';


dayjs.extend(customParseFormat);
const UniversityDegree = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { eid } = useParams();
  const [form] = Form.useForm();
  const { isCertificateUpdating, certiMsg, certiErrorMsg } = useSelector(state => state.steps);
  const { isDegreeLoading, degreeList, isDegreeEmpty } = useSelector(state => state.expert);
  const [degreeHtml, setDegreeHtml] = useState('');
  const { isAdminDeleteDegreeLoading } = useSelector(state => state.admin);
  const [tempDegree, setTempDegree] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);

  // Must add isCertificateUpdating otherwise page renders with stale data
  useEffect(() => {
    const getExpertDegrees = () => {
      dispatch({
        type: 'EXPERT_DEGREE_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };

    getExpertDegrees();

    const getExpertMainSub = () => {
      dispatch({
        type: 'EXPERT_MAINSUB_CAT_REQUEST',
        formData: { expert_id: eid || getUserData().id },
      });
    };
    getExpertMainSub();
  }, [isAdminDeleteDegreeLoading, isCertificateUpdating, eid, dispatch]);

  useEffect(() => {
    if (!isDegreeLoading && isDegreeEmpty !== undefined) {
      let degrees;
      if (degreeList.length === 0) {
        degrees = [
          {
            expert_id: eid || getUserData().id,
            name: '',
            level: 1,
            issuance_date: null,
            institution: '',
          },
        ];
      } else if (degreeList.length > 0) {
        degrees = degreeList.map(item => ({
          ...item,
          issuance_date: dayjs(item.issuance_date, 'YYYY-MM-DD'),
        }));
      }
      setTempDegree({
        degrees,
      });
    }
  }, [isDegreeLoading, degreeList, isDegreeEmpty, isAdminDeleteDegreeLoading, eid]);

  useEffect(() => {
    const onFinish = values => {
      let tmpDegrees = [];
      if (values?.degrees && values.degrees.length > 0) {
        values.degrees.forEach((item, index) => {
          let tmpObj = {};

          tmpObj.issuance_date = moment(new Date(item.issuance_date)).format('YYYY-MM-DD');
          tmpObj.expert_id = eid || getUserData().id;

          if (item.name) {
            tmpObj.name = item.name;
          }

          tmpObj.level = values.degrees.length - index;

          if (item.institution) {
            tmpObj.institution = item.institution;
          }

          if (item.id) {
            tmpObj.id = item.id;
          }

          tmpDegrees.push(tmpObj);
        });
      }

      if (tmpDegrees.length > 0) {
        dispatch({
          type: 'EXPERT_CERTIFICATE_UPDATE_REQUEST',
          formData: tmpDegrees,
          moduleType: 'degree',
        });
      } else {
        message.error('Please add at least one degree');
      }
    };

    const handleDegreeDelete = (field, id, clientRemove) => {
      // handle removal of not yet saved degrees and do client side delete
      // field.name is the index of the degree in the form list
      clientRemove(field.name);
      if (id) {
        // if it was saved, it should have an id, dispatch delete action
        dispatch({ type: 'ADMIN_DELETE_DEGREE', id });
      }
    };

    const loadDegrees = () => {
      return (
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          initialValues={tempDegree}
        >
          <Form.List name="degrees">
            {(fields, { add, remove }) => (
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {fields.map(field => (
                  <div key={field.name} className="certificate-section">
                    <Row gutter={30} className={'mb20'}>
                      {fields.length > 1 && (
                        <div className="remove-section-icon-wrapper">
                          <CloseOutlined
                            onClick={() =>
                              handleDegreeDelete(
                                field,
                                form.getFieldValue(['degrees', field.name, 'id']),
                                remove,
                              )
                            }
                            className="cursor-p"
                          />
                        </div>
                      )}
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                          label="Degree"
                          name={[field.name, 'name']}
                          className="form-field"
                          rules={[{ required: true, message: 'Please enter degree name' }]}
                        >
                          <Input size="large" placeholder="Degree" />
                        </Form.Item>
                        <Form.Item
                          label="Issuance Date"
                          name={[field.name, 'issuance_date']}
                          rules={[
                            {
                              type: 'object',
                              required: true,
                              message: 'Please select date!',
                            },
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} size="large" />
                        </Form.Item>
                        <Form.Item
                          label="Institution"
                          name={[field.name, 'institution']}
                          className="form-field"
                          rules={[{ required: true, message: 'Please enter institution' }]}
                        >
                          <Input size="large" placeholder="Institution" />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} name={[field.name, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} name={[field.name, 'level']}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <AddAnotherCard handleClick={add} text={'add another degree'} />
              </Col>
            )}
          </Form.List>
          <Divider className="mt40 mb40" />
          <Form.Item>
            <Button
              type="primary"
              size="middle"
              className="mt5"
              disabled={isCertificateUpdating}
              loading={isCertificateUpdating}
              onClick={() => handleButtonClick()}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      );
    };


    if (!tempDegree?.degrees?.length) {
      return;
    }

    setDegreeHtml(loadDegrees());
  }, [tempDegree, form, isCertificateUpdating, eid, dispatch]);

  const inputRef = useRef(null);
  useEffect(() => {
    inputRef?.current?.focus({
      cursor: 'start',
    });
  }, [degreeHtml]);

  useEffect(() => {
    if (!isCertificateUpdating && certiMsg === 'success' && certiErrorMsg === '') {
      handleNext('certification');
      dispatch({ type: 'CLEAR_STEPS' });
    } else if (!isCertificateUpdating && certiErrorMsg !== '') {
      dispatch({ type: 'CLEAR_STEPS' });
      message.error(certiErrorMsg || 'Something went wrong');
    }
  }, [isCertificateUpdating, certiMsg, certiErrorMsg, dispatch, handleNext]);

  const handleButtonClick = () => {
    if (form.getFieldValue('degrees')?.length > 1 || Cookies.get('expert_signup_degree_warning_message') === '1') {
      form.submit();
    } else {
      setShowWarningModal(true);
    }
    return;
  }

  const handleWarningModelGotIt = () => {
    Cookies.set('expert_signup_degree_warning_message', '1', { expires: 100 });
    setShowWarningModal(false);
  }

  return (
    <div>
      <div className="site-card-border-less-wrapper card-wrapper">
        <Card bordered={false}>
          {isDegreeLoading && <Spin />}
          {!isDegreeLoading && (
            <>
              <p className="m-title tl mb-7 flex-y">
                <p className="d-flex">
                  <span className="back-icon" onClick={() => handleBack('degree')}>
                    <IoMdArrowRoundBack />
                  </span>
                  <span>I have the following education</span>
                </p>
                <p className="help-text d-flex">
                  Insert highest degree first, as this will appear on your public profile
                </p>
              </p>
              {degreeHtml}
            </>
          )}
        </Card>
      </div>
      {
        showWarningModal &&
        <WarningModal
          showWarningModal={showWarningModal}
          handleWarningModelGotIt={handleWarningModelGotIt}
          text={`Make sure to add all your degrees! Including all your degrees helps clients understand your full educational background.Use the <strong>"Add Another Degree"</strong> button below for each one.`}
        />
      }
    </div>
  );

};



export default UniversityDegree;