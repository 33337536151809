/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { formatAmount } from '../../../helpers/functions';

function TaxInvoice({ tax, quotation, quotationDetail }) {
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  useEffect(() => {
    setIsCouponApplied(tax.coupon_id !== undefined && tax.coupon_id !== null);
  }, [tax]);
  return (
    <div className="print-invoice">
      <div className="table1">
        <table border={1}>
          {tax.quotation !== null && (
            <tr>
              <td>
                <b>{`Service Fee - ${quotationDetail?.expert?.first_name} ${quotationDetail?.expert?.last_name}`}</b>
              </td>
              <td>
                <b>{formatAmount(quotation?.price)}</b>
              </td>
            </tr>
          )}

          <tr style={{ color: 'green' }}>
            <td>Coupons</td>
            {isCouponApplied && (
              <td>
                <b>{formatAmount(tax.total - tax.total_discount)}</b>
              </td>
            )}
            {!isCouponApplied && '-'}
            {/* <td><b>{`${tax?.grand_total_discount ? (formatAmount(tax?.grand_total_discount - tax?.grand_total)) : '-'}`}</b></td> */}
          </tr>
          {isCouponApplied && (
            <tr>
              <td>
                <b>Net Service Fee</b>
              </td>
              {isCouponApplied && (
                <td>
                  <b>{formatAmount(tax?.total_discount)}</b>
                </td>
              )}
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
          </tr>
          {tax?.hst && tax.hst !== null && (
            <tr>
              <td>{tax?.hst?.name}</td>
              <td>
                {isCouponApplied
                  ? formatAmount(tax?.hst?.amount_discount)
                  : formatAmount(tax?.hst?.amount)}
              </td>
            </tr>
          )}
          <tr>
            <td>{tax?.marketplace_fee?.name}</td>
            <td>
              {isCouponApplied
                ? formatAmount(tax?.marketplace_fee?.amount_discount)
                : formatAmount(tax?.marketplace_fee?.amount)}
            </td>
          </tr>

          {tax?.marketplace_fee_hst && tax.marketplace_fee_hst !== null && (
            <tr>
              <td>{tax?.marketplace_fee_hst?.name}</td>
              <td>
                {isCouponApplied
                  ? formatAmount(tax?.marketplace_fee_hst?.amount_discount)
                  : formatAmount(tax?.marketplace_fee_hst?.amount)}
              </td>
            </tr>
          )}

          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>
              <b>
                {tax?.grand_total_discount
                  ? formatAmount(tax?.grand_total_discount)
                  : formatAmount(tax?.grand_total)}{' '}
                {}
              </b>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default TaxInvoice;
