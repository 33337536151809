import React from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const FAQs = props => {
  const { type, count } = props;

  return (
    <div className="faq">
      <Collapse bordered={false} expandIconPosition="right" defaultActiveKey={['1']}>
        {type === 'expert'
          ? ExpertFaqs.slice(0, count).map(item => {
              return (
                <Panel header={item.question} key={item.id}>
                  <p>{item.ans}</p>
                </Panel>
              );
            })
          : ClientFaqs.slice(0, count).map(item => {
              return (
                <Panel header={item.question} key={item.id}>
                  <p>{item.ans}</p>
                </Panel>
              );
            })}
      </Collapse>
    </div>
  );
};

export default FAQs;

const ExpertFaqs = [
  {
    id: '1',
    question: 'What is Accountizer?',
    ans: (
      <ul>
        <li>
          A marketplace platform dedicated to accounting services that connect talented accounting
          professionals like you with your potential clients. Accountizer is the first platform of
          its kind.
        </li>
      </ul>
    ),
  },
  {
    id: '2',
    question: 'What is the AccounTech concept Accountizer embraces and implements?',
    ans: (
      <ul>
        <li>
          The AccounTech concept combines the accounting industry and software technologies. It is
          designed to serve accounting professionals and their clients with modernized solutions for
          automating workflows to provide more efficient and effective services with reduced costs.
        </li>
      </ul>
    ),
  },
  {
    id: '3',
    question: 'Who can join Accountizer as an expert?',
    ans: (
      <ul>
        <li>
          All professionals in the accounting field, including CPAs, accountants, tax preparers,
          business advisors, and bookkeepers.
        </li>
        <br />
        <li>
          Financial experts with accounting niches who would like to give a hand to their
          colleagues, who are unfamiliar with these topics.
        </li>
      </ul>
    ),
  },
  {
    id: '4',
    question: 'We are an accounting firm, can we join Accountizer?',
    ans: (
      <ul>
        <li>
          Yes. Whether you are a small- or medium-sized accounting firm, and whether you provide
          accounting, tax, business advice, or bookkeeping, your firm can join Accountizer. Firms
          can also open expert accounts for their staff members.
        </li>
      </ul>
    ),
  },
  {
    id: '6',
    question: 'Why should I join Accountizer?',
    ans: (
      <ul>
        <li>
          Accountizer works with the best accounting talent in Canada. Joining the Accountizer
          community shows that you were vetted for your qualifications, skills, and knowledge, and
          helps build a level of trust with clients.
        </li>
        <br />
        <li>
          So, if you are in your early years of professional practice or getting started, we save
          you the massive time and effort needed to build your professional reputation from scratch.
        </li>
        <br />
        <li>
          Even if you have a respectable amount of experience, you still need to sustain your market
          share. And you will need to sustain it in a market with fierce online competition. We're
          helping you to build and bolster your online presence.
        </li>
      </ul>
    ),
  },
  {
    id: '7',
    question: 'What are the benefits of joining Accountizer as an accounting expert?',
    ans: (
      <ul>
        ✓ Gain extended market exposure
        <br />
        <br />
        ✓ Attract new clients, and increase your market share
        <br />
        <br />
        ✓ Save on marketing like social media, webinars, creating content, and SEO
        <br />
        <br />
        ✓ You will be joining an extensive network of experienced accountants
        <br />
        <br />
        ✓ You will receive timely updates on industry news and trends
        <br />
        <br />
        ✓ Accountizer enables you to track your performance and skill level
        <br />
        <br />
        ✓ Saving on your Continuing Professional Development (CPD) expenses
        <br />
        <br />
        ✓ Saving on your overhead costs, as your work may go entirely virtual
        <br />
        <br />
        ✓ App-secured portals so you can safely communicate with your new clients
        <br />
        <br />
        ✓ Save the time and cost of tracking and collecting receivables
        <br />
        <br />
      </ul>
    ),
  },
  {
    id: '8',
    question: 'What information do I need to provide before joining Accountizer?',
    ans: (
      <ul>
        <li>
          We accept candidates with at least 2 or 3 years of experience in any of the accounting,
          tax, business advice, or bookkeeping fields.
        </li>
        <br />
        <li>
          We're an equal opportunity platform, so we only need the information relevant to your
          professional experience and qualifications. This is so we can provide the client with
          reliable, quality services.
        </li>
      </ul>
    ),
  },
  {
    id: '9',
    question: 'What client categories am I going to work with?',
    ans: (
      <ul>
        <li>
          Individuals
          <br />
          <br />
        </li>
        <li>
          Startups
          <br />
          <br />
        </li>
        <li>
          Small-sized businesses
          <br />
          <br />
        </li>
        <li>
          Medium-sized businesses
          <br />
          <br />
        </li>
        <li>
          Non-profit organizations
          <br />
          <br />
        </li>
        <li>
          Financial advisors, executives, and other financial decision-makers
          <br />
        </li>
      </ul>
    ),
  },
  {
    id: '10',
    question: 'Where are Accountizer clients located?',
    ans: (
      <ul>
        <li>
          For the time being, all our clients are in Canada. But we will extend globally in the
          future.
        </li>
      </ul>
    ),
  },
  {
    id: '11',
    question: 'Do I need to relocate?',
    ans: (
      <ul>
        <li>
          Accountizer is a fully remote platform- you can work from anywhere, as long as you are
          connected to the internet.
        </li>
      </ul>
    ),
  },
  {
    id: '12',
    question: 'How will I communicate with my clients?',
    ans: (
      <ul>
        <li>
          All communication with the client is made within the platform to ensure security. We've
          made all the required communication and work organization tools available.
        </li>
      </ul>
    ),
  },
  {
    id: '13',
    question: 'How does Accountizer identify my potential clients?',
    ans: (
      <ul>
        <li>
          Accountizer is built by accountants for accountants, so it can easily understand your
          capabilities and strength points. It will connect you with clients using its proprietary
          algorithmic solution, which matches you with clients who need your precise skills and
          expertise.
        </li>
      </ul>
    ),
  },
  {
    id: '14',
    question: 'How does the process work for experts?',
    ans: (
      <ol>
        <li itemType="1">Provide expert advice and solutions</li>
        <br />
        <ul>
          <li>
            You will first receive the client's message that explains their issue which needs
            professional help. Provide your expert answer and any necessary explanation and propose
            a solution(s).
          </li>
          <br />
          <ul>
            <li>
              Before answering, you should ensure that the information provided by the client is
              enough information, if not, you need to ask the client to provide you with the missing
              information.
              <br />
            </li>
          </ul>
          <br />
          <li>
            When you have all the necessary information, fill out a Question Notification Form with
            an adequate explanation of the proposed solution for the client's issue, and submit the
            form.
          </li>
          <br />
          <li>
            If you are one of the experts who opted to provide the client with advice and answers
            only, and the client’s problem can be solved by one of the other services you are
            providing, such as virtual sessions, you can include this information in your response
            to your client.
          </li>
          <br />
          <li>
            If you are an expert who opted to provide a full range of services, either a one-time
            assignment such as virtual sessions, filing tax returns, or a monthly/annual plan such
            as bookkeeping, you may propose your other services to your client.
          </li>
        </ul>
        <br />
        <li itemType="1">Provide a full range of professional services</li>
        <br />
        <ul>
          <li>
            If the client decides to take advantage of one of your services after you answer their
            question, or they go for your service directly, you will receive a Request for Quotation
            Notification to your dashboard, fill it out with a detailed explanation of the service
            including price, and then submit the completed form.
          </li>
          <br />
          <li>
            Once you receive a Quotation Acceptance Notification, the assignment will appear as an
            Open Assignment in your dashboard, once you finish the project successfully and get the
            client's consent, you can mark the work as a Closed Assignment
          </li>
        </ul>
      </ol>
    ),
  },
  {
    id: '15',
    question: 'Does Accountizer set my service fees?',
    ans: (
      <ul>
        <li>No, you determine the price of your professional services.</li>
      </ul>
    ),
  },
  {
    id: '16',
    question: 'How will experts get paid?',
    ans: (
      <ul>
        <li>
          For the one-time assignments, you'll earn the service fee when you finalize the work, get
          the client's consent, and mark the client assignment as a Closed Assignment.
        </li>
        <br />
        <li>
          For long-term subscriptions, you'll earn monthly at the end of each month until the client
          cancels the subscription plan.
        </li>
        <br />
        <li>
          Any amount you earn during a calendar month will be transferred to your bank account
          within the first ten days of the succeeding month.
        </li>
      </ul>
    ),
  },
  {
    id: '17',
    question:
      'How much will the client be charged for getting answers to their technical question?',
    ans: (
      <ul>
        <li>Clients are charged according to the rates you set for your professional services.</li>
        <br />
        <ul>
          <li>
            However, each new client’s first question will be free of charge to introduce more
            clients to your services.
          </li>
        </ul>
      </ul>
    ),
  },
  // comment out rather than remove for easier near future reference/modification
  // {
  //     id: "18",
  //     question: "What if you did not get me any business for a month?",
  //     ans:
  //         <ul>
  //             <li>
  //                 Typically, starting from the date you join us and onwards, you are eligible for CA$10
  //                 monthly (withdrawable semiannually) or your monthly earnings, whichever is higher.
  //             </li>
  //         </ul>
  // },
  {
    id: '19',
    question: "Is there a fee for Accountizer's service?",
    ans: (
      <ul>
        <li>
          Joining our community of experts is FREE. however, our App service fee is 10% chargeable
          upon transferring your earnings to you.
        </li>
      </ul>
    ),
  },
  {
    id: '20',
    question: 'How does Accountizer contribute to my education and professional development?',
    ans: (
      <ul>
        <li>
          Accountizer has market exposure, so we get updates on market trends, and therefore new and
          different client needs. At the same time, we can help identify your career strengths and
          weaknesses, and we can continuously assess your professional level through client
          feedback.
        </li>
        <br />
        <li>
          We are help professionals constantly improve their skills and will advise them of the
          knowledge areas they need to develop to meet the market needs.
        </li>
      </ul>
    ),
  },
  // comment out rather than remove for easier near future reference/modification
  // {
  //     id: "21",
  //     question: "Does Accountizer funds my Continuing Professional Development plan?",
  //     ans:
  //         <ul>
  //             <li>
  //                 Yes, each expert is typically eligible for CA$100 annually, spendable on accredited CPD
  //                 training courses.
  //             </li>
  //             <br />
  //             <li>
  //                 We recommend using the fund in an annual subscription for one of the accredited
  //                 CPE/CPD eLearning platforms, so you get unlimited access to hundreds of credited hours
  //                 for various topics while getting the best use of your funds.
  //             </li>
  //         </ul>
  // },
  {
    id: '22',
    question: "What is Accountizer's User Feedback Initiative (UFI) Program?",
    ans: (
      <ul>
        <li>
          A program that helps us gather user insights about their experiences with our service:
          participants should provide feedback and opinion about their experience using our service.
        </li>
      </ul>
    ),
  },
  {
    id: '23',
    question: 'How to join?',
    ans: (
      <ul>
        <li>
          You are welcome to sign up through our experts' page; you'll need to fill up an online
          application form with information about your qualifications, designations, experiences,
          and area(s) of knowledge.
        </li>
        <br />
        <li>
          Upon submitting your application, we'll immediately start our screening process; your
          account will be activated once the screening process is complete, and your application is
          accepted. You'll receive a congratulatory email containing a temporary password to log in
          to your App dashboard panel
        </li>
      </ul>
    ),
  },
  {
    id: '24',
    question: 'Is my information secured?',
    ans: (
      <ul>
        <li>
          ll the information you provide us during the joining process or later is solely to have a
          sound understanding of qualifications, designations, experiences, and areas of knowledge
          to aptly match you with the perfect clients. We guarantee your information will not be
          used for any other purposes.
        </li>
        <br />
        <li>Clients will only be able to see your profile page, which contains the following:</li>
        <br />
        <ul>
          <li>Your profile picture with first and last name</li>
          <br />
          <li>
            Your qualifications and/or designations, and a URL to its awarding body’s online
            directory
          </li>
          <br />
          <li>Your city of residence</li>
          <br />
          <li>The languages that you speak</li>
          <br />
          <li>Service that you provide and their price ranges</li>
          <br />
          <li>Industry experience</li>
          <br />
          <li>Reviews from your previous clients</li>
          <br />
        </ul>
      </ul>
    ),
  },
];

const ClientFaqs = [
  {
    id: '1',
    question: 'What is Accountizer?',
    ans: (
      <ul>
        <li>
          We are an online marketplace platform dedicated to accounting services, connecting our
          clients with talented accounting, tax, and business advisory professionals.
        </li>
      </ul>
    ),
  },
  {
    id: '2',
    question: 'What is the AccounTech concept that Accountizer embraces and implements?',
    ans: (
      <ul>
        <li>
          The AccounTech concept combines the accounting industry with software technologies.
          Accountizer is designed to serve clients by matching them with the right accounting
          professionals. It uses modernized solutions for automating workflows- this allows us to
          provide more efficient, and more effective services, with reduced costs.
        </li>
      </ul>
    ),
  },
  {
    id: '3',
    question: 'Why should I use Accountizer?',
    ans: (
      <ul>
        <li>
          Accountizer connects its users with the best financial professionals in Canada in no time.
        </li>
        <br />
        <li>
          Accountizer is the right choice for you if you need tax, bookkeeping, accounting, business
          evaluation, payroll management services, and any other financial services.
        </li>
      </ul>
    ),
  },
  {
    id: '4',
    question: 'What makes Accountizer stand out from other online solutions?',
    ans: (
      <ul>
        <li>
          Our service does not end once you are matched with your professional accounting experts;
          it only just starts. We accompany you throughout your online journey until you receive a
          remarkable, and a complete service.
        </li>
        <br />
        <li>
          We help you find the best matched expert who fits your needs exactly. So even if your
          financial background is not comprehensive, you will not suffer or face dilemmas when
          choosing the best expert.
        </li>
        <br />
        <li>
          You have direct access to our pool of experts, and if you have a quick question, you may
          directly contact any expert for free.
        </li>
        <br />
        <li>
          The range of service prices is predetermined, meaning a guarantee of complete transparency
          and the best prices in the market.
        </li>
        <br />
        <li>
          You have access to a live status tracker, so you can track your job and receive progress
          update notifications: from project inception, until project delivery.
        </li>
        <br />
        <li>
          All of your completed jobs will be securely archived in your dashboard, so you have a full
          archive of your documents in case you need them in the future.
        </li>
        <br />
        <li>
          Our expert admission process is carefully designed to guarantee you secure access to
          vetted and top-end professionals.
        </li>
        <br />
        <li>
          We've combined the top, most-talented, financial professionals under one symbolic roof,
          guaranteeing you can find professionals to suit all your needs in one place. Plus, this
          way provides you with various options to match your budget.
        </li>
        <br />
        <li>
          We are a specialized platform dedicated to accounting and taxation services, guaranteeing
          to be the best online accounting talent solution in Canada.
        </li>
      </ul>
    ),
  },
  {
    id: '5',
    question: 'What are the benefits of using Accountizer?',
    ans: (
      <ul>
        <li>
          <b>Saving money:</b> through competitive prices for service(s) rendered through highly
          professional experts.
        </li>
        <br />
        <li>
          <b>Time and effort saving:</b> Accountizer handles all the processes once you start
          selecting the service you require.
        </li>
        <br />
        <li>
          <b>Safe and guaranteed:</b> App-secured portals allow you to communicate safely with your
          selected professionals. Your transactions through Accountizer web app are secure from the
          beginning until you receive your service(s) at a satisfactory level. This comes with a
          money-back guarantee if the service is not satisfactory.
        </li>
        <br />
        <li>
          <b>Eliminate borders:</b> Wherever you are, our professionals are ready to provide you
          with the required service(s) remotely, around the clock.
        </li>
      </ul>
    ),
  },
  {
    id: '6',
    question: 'What business categories does Accountizer serve?',
    ans: (
      <ul>
        <li>individuals</li>
        <br />
        <li>Startups</li>
        <br />
        <li>Small-sized businesses</li>
        <br />
        <li>Medium-sized businesses</li>
        <br />
        <li>Nonprofit organizations</li>
        <br />
        <li>Financial advisors, executives, and other financial decision-makers</li>
      </ul>
    ),
  },
  {
    id: '7',
    question: 'Where are Accountizer professionals located?',
    ans: (
      <ul>
        <li>
          For the time being, our professionals are all in Canada. However, we will extend globally
          in the future.
        </li>
      </ul>
    ),
  },
  {
    id: '8',
    question: 'Do I need to physically visit Accountizer to get my service?',
    ans: (
      <ul>
        <li>
          Service(s) rendered through Accountizer are fully remote. You can be located anywhere in
          Canada to request your service. All you need is a stable internet connection.
        </li>
      </ul>
    ),
  },
  {
    id: '9',
    question: 'How will I communicate with the professional(s)?',
    ans: (
      <ul>
        <li>
          All communication with the professional(s) is made within the platform to ensure security.
          We've made all the required communication and work organization tools available.
        </li>
      </ul>
    ),
  },
  {
    id: '10',
    question: 'How does Accountizer match me with my potential Professional (s)?',
    ans: (
      <ul>
        <li>
          Accountizer is built by accountants who apply a proprietary algorithmic solution and
          consistently work to improve its features and function. Accountizer takes into
          consideration simplification, and understanding the needs of the clients (even those with
          no financial background), so we can perfectly match them with the proper professional(s).
        </li>
      </ul>
    ),
  },
  {
    id: '11',
    question: 'How does Accountizer work?',
    ans: (
      <ol>
        <li>
          <b>Describe your needs</b>
        </li>
        <br />
        <ul>
          <li>
            Explain your case, and answer our few simple questions that were designed to help us
            better understand your needs. Even if you are unsure about the help you need, no
            worries; you are in the right place, and we can still help.
          </li>
          <br />
        </ul>
        <li>
          <b>Get matched with an expert</b>
        </li>
        <br />
        <ul>
          <li>
            We will connect you with a carefully picked professional who is pre-vetted for
            qualification and training, and for experience in handling clients with similar
            requirements.
          </li>
          <br />
          <li>
            Alternatively, pick your preferred professional from our pre-vetted experts' pool and
            start communicating with your chosen professional right away.
          </li>
          <br />
        </ul>
        <li>
          <b>Pay only when you are fully satisfied</b>
        </li>
        <br />
        <ul>
          <li>
            After you accept the expert’s quote for the agreed-upon service(s), you will be charged
            for the service. However, the service fee will not be released to the experts until they
            successfully complete your job.
          </li>
        </ul>
      </ol>
    ),
  },
  {
    id: '12',
    question:
      'What If I decided as a user to select an expert other than the one proposed by the Accountizer App?',
    ans: (
      <ul>
        <li>
          No problem! Our platform enables clients to select any expert they feel will best serve
          them. Our mission is to ease the process for our clients by granting them 100%
          satisfaction and secured transaction(s). You may opt to use our tool, which is designed to
          locate the best expert for you, or you may search our experts' pool and choose the best
          one to serve you.
        </li>
      </ul>
    ),
  },
  {
    id: '13',
    question: 'How does Accoutnizer guarantee the quality of the services provided to me?',
    ans: (
      <ul>
        <li>
          Our expert admission process is carefully designed to guarantee you secure access to
          vetted and top-end professionals.
        </li>
        <br />
        <li>Continuous professional development training is provided to each professional.</li>
        <br />
        <li>
          Customer ratings and reviews on completed jobs are considered when assessing our service
          providers.
        </li>
      </ul>
    ),
  },
  {
    id: '14',
    question: 'Does Accountizer set my service fees?',
    ans: (
      <ul>
        <li>
          No, the professionals determine the price of their proposed services, and share a
          quotation with you for your approval before rendering any service. You can first get an
          idea about each professional's services, and their range of prices from their profile.
        </li>
      </ul>
    ),
  },
  {
    id: '15',
    question: 'How will I pay the experts?',
    ans: (
      <ul>
        <li>
          As the client and user, you will pay a one-time fee to Accountizer as escrow for the
          one-time assignments, and we will be responsible for paying the professional upon
          verification of service rendered at a satisfactory level as agreed.
        </li>
        <br />
        <li>
          For long-term subscriptions, you as a client and user will pay on a monthly basis to
          receive the service during the subscription period, and you may cancel the subscription at
          any time without obligation.
        </li>
        <br />
        <li>All your invoices will be automatically issued and archived in your dashboard.</li>
      </ul>
    ),
  },
  {
    id: '16',
    question: 'Am I able to rate the expert?',
    ans: (
      <ul>
        <li>Yes, after the job is complete.</li>
      </ul>
    ),
  },
  {
    id: '17',
    question: "What is Accountizer's User Feedback Initiative (UFI) Program?",
    ans: (
      <ul>
        <li>
          A program that helps us gather user insights about their experiences with our service:
          participants should provide feedback and opinion about their experience using our service.
        </li>
      </ul>
    ),
  },
  {
    id: '18',
    question: 'Is my information secure?',
    ans: (
      <ul>
        <li>
          All the information you provide us during the service process is confidential, and will
          not be shared as per our privacy policy and terms and conditions.
        </li>
      </ul>
    ),
  },
  {
    id: '19',
    question: 'May Accountizer ask me to share my username, password, or payment details?',
    ans: (
      <ul>
        <li>
          Accountizer will never request any information such as usernames, passwords, or payment
          cards through messages, phone calls, or through online chatting. Make sure never to share
          such information with anonymous parties.
        </li>
      </ul>
    ),
  },
  {
    id: '20',
    question: 'How do you need to be to use Accountizer?',
    ans: (
      <ul>
        <li>
          The website and/or the services are not intended for use by anyone under the age of 18,
          nor does Accountizer knowingly collect or solicit personal Information from anyone under
          the age of 18. If you are under 18, you may not attempt to register for the website or
          send any information about yourself to us. If you are a parent or legal guardian of a
          person under 18 and believe that we might have any information from or about such a
          person, please contact us at{' '}
          <a style={{ textDecoration: 'underline' }} href="mailto:admin@accountizer.ca">
            admin@accountizer.ca
          </a>
        </li>
      </ul>
    ),
  },
  {
    id: '21',
    question: "How do I reach Accountizer's support team?",
    ans: (
      <ul>
        <li>
          Please visit our Contact Us page, leave your question, and we'll answer you as soon as
          possible.
        </li>
        <br />
        <li>
          Or email us at{' '}
          <a style={{ textDecoration: 'underline' }} href="mailto:admin@accountizer.ca">
            admin@accountizer.ca
          </a>
        </li>
      </ul>
    ),
  },
];
