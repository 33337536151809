import React from 'react'

import loader from '../../assets/images/loader.gif'

export default function Loader() {
    return (
        <div >
            <img style={{ width: "75px",border: "1px solid #e1dfdf", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px" }} src={loader} alt="loader" />
        </div>
    )
}
