import { StarTwoTone } from '@ant-design/icons';
import React from 'react';

// comman function for capsule
import { getRandomLightHexColor } from '../../helpers/functions';

// import img from '../../assets/images/myImgs/dollar-1159943_1280.png';

function Capsule({ item, handleCapsuleClickEvent, localData, icons, isIconDisplay }) {
  let selected = false;
  if (localData && localData !== '' && typeof localData === 'object') {
    Object.values(localData).forEach(element => {
      if (typeof element === 'object' && element?.id === item?.id && element?.name === item?.name) {
        selected = true;
      }
    });
  }

  let iconPath = (isIconDisplay && icons?.[item?.name.replace(/\s+/g, '').toLowerCase()]) || null;

  return (
    <span
      className="capsule"
      style={{
        borderColor: selected ? 'transparent' : getRandomLightHexColor(160, 230),
        borderWidth: selected ? '5px' : '3px',
        backgroundColor: selected ? '#1c64f2' : 'transparent',
        color: selected ? 'white' : 'black',
      }}
    >
      <button
        type="button"
        style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
        onClick={() => handleCapsuleClickEvent(item)}
      >
        <div className="capsule_text" style={{ fontWeight: selected ? 'bold' : 'normal' }}>
          {item?.name === 'Bookkeeping' && (
            <img
              height={40}
              src={require('../../assets/images/questions_icons/Bookkeeping.png')}
              alt=""
            />
          )}
          {item?.name === 'Payroll Management' && (
            <img
              height={40}
              src={require('../../assets/images/questions_icons/staff.png')}
              alt=""
            />
          )}
          {item?.name === 'Accounting' && (
            <img
              height={40}
              src={require('../../assets/images/questions_icons/accounting.png')}
              alt=""
            />
          )}
          {item?.name === 'Business analysis' && (
            <img
              height={40}
              src={require('../../assets/images/questions_icons/chart.png')}
              alt=""
            />
          )}
          {item?.name === 'Tax Preparation' && (
            <img
              height={40}
              src={require('../../assets/images/questions_icons/dollar.png')}
              alt=""
            />
          )}

          {' ' + item?.name || 'N/A'}
        </div>
      </button>
    </span>
  );
}

export default Capsule;
