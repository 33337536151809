import React, { useEffect, useRef, useState } from 'react'
import { animatedMehtod, cuurentBrowser } from '../../helpers/functions';
import { useInView, animated } from 'react-spring';
import { Card, Col, Row } from 'antd';

export default function HowItWork({ item, index }) {
    const getRootMargin = () => (cuurentBrowser() === 'Firefox') ? '-40% 0%' : '-75% 0%';

    const [playVideo, setPlayVideo] = useState(false);

    const ifreameRef = useRef();

    const [workingStepsRef, workingStepsSpring] = useInView(animatedMehtod, {
        rootMargin: getRootMargin(),
        once: true,
    })
    const [workingStepsLineRef, workingStepsLineSpring] = useInView(animatedMehtod, {
        rootMargin: getRootMargin(),
        once: true,
    })

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setPlayVideo(ifreameRef.current.title);
                    // observer.disconnect(); // Stop observing after it's visible
                } else {
                    setPlayVideo(false);

                }
            },
            {
                threshold: 0.8, // 10% of the element should be visible
            }
        );

        if (ifreameRef.current) {
            observer.observe(ifreameRef.current);
        }

        return () => observer.disconnect();

    }, []);

    return (
        (index % 2 === 0) ?
            <>
                <animated.div style={workingStepsSpring} ref={workingStepsRef}>
                    {index !== 0 ?
                        <animated.div key={index} id={index} style={workingStepsLineSpring} ref={workingStepsLineRef}>
                            <div className="w100 line-container" >
                                <span className='line'></span>
                            </div>

                        </animated.div>
                        : ''}

                    <Card className={index === 0 ? 'working-steps-card mt-10' : 'working-steps-card'}>
                        <Row
                            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
                            className="mt50 benefits-row-container"
                            gutter={[75]}
                        >

                            <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex-center benefits-description-wrapper align-start"
                            >
                                <div className="steps-num-box">
                                    <span className="steps-num">{index + 1}</span>
                                </div>
                                <div className="benefits-sub-text pl-6">
                                    <h3 className="m-title ac-heading">{item.title}</h3>
                                    <p className="sub-title ac-content">{item.desc}</p>
                                </div>
                            </Col>
                            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
                                <div>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={item.vidURL + "&muted=1&loop=1" + ((playVideo == 'Video_' + index) && "&autoplay=1")}
                                        frameborder="0"
                                        allow="autoplay;encrypted-media;fullscreen;picture-in-picture;accelerometer; clipboard-write;"
                                        allowfullscreen={true}
                                        style={{ minHeight: '40vh' }}
                                        title={'Video_' + index}
                                        ref={ifreameRef}

                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </animated.div>
            </>
            :
            <>
                <animated.div style={workingStepsSpring} ref={workingStepsRef}>
                    <animated.div style={workingStepsLineSpring} ref={workingStepsLineRef}>
                        <div className="w100 line-container" >
                            <span className='line'></span>
                        </div>
                    </animated.div>
                    <Card className='working-steps-card'>
                        <Row
                            style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}
                            className="mt50 benefits-row-container"
                            gutter={[75]}
                        >
                            <Col className="benefits-image-wrapper" xs={24} sm={12} md={12} lg={12} xl={12}>
                                <div>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={item.vidURL + "&muted=1&loop=1" + ((playVideo == 'Video_' + index) && "&autoplay=1")}
                                        frameborder="0"
                                        allow="autoplay;encrypted-media;fullscreen;picture-in-picture;accelerometer; clipboard-write;"
                                        style={{ minHeight: '40vh' }}
                                        title={'Video_' + index}
                                        ref={ifreameRef}
                                        allowfullscreen={true}

                                    />
                                </div>
                            </Col>
                            <Col
                                xs={24}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                className="d-flex-center benefits-description-wrapper align-start"
                            >
                                <div className="benefits-sub-text pr-6">
                                    <h3 className="m-title ac-heading">{item.title}</h3>
                                    <p className="sub-title ac-content">{item.desc}</p>
                                </div>
                                <div className="steps-num-box steps-num-box-black">
                                    <span className="steps-num">{index + 1}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </animated.div>
            </>
    )
}
